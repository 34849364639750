import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// const SingleInvoice = Loader(
//   lazy(() => import('src/content/Invoices/search/InvoiceDetail'))
// );
// const Search = Loader(lazy(() => import('src/content/Invoices/search')));

const Invoices = Loader(lazy(() => import('src/content/Invoices/')));

const invoicesRoutes = [
  {
    path: '/',
    element: <Navigate to="list" replace />
  },
  {
    path: 'list',
    element: <Invoices />
  }
  // {
  //   path: ':invoiceId',
  //   // element: <SingleInvoice />
  //   element: <Overview />
  // },
  // {
  //   path: 'search',
  //   // element: <Search />
  //   element: <Overview />
  // }
];

export default invoicesRoutes;
