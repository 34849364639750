import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { backOff } from 'exponential-backoff';

import { format, parseISO, isToday } from 'date-fns';

// import {
//   listCustomerAppointmentsAPI,
//   createAppointmentAPI,
//   listAppointmentsAPI,
//   getAppointmentAPI,
//   editAppointmentAPI,
//   transferAppointmentAPI
//   cancelAppointmentAPI,
//   confirmAppointmentAPI,
//   checkinAppointmentAPI,
//   listFreeAppointmentBookingSlotsAPI,
// } from '../content/Appointments/api';
// import { createCommunicationAPI } from 'src/content/Communications/api';
// import { updateEvent } from 'src/slices/calendar';
// import {
//   getPatientInvoices,
//   listQueueAppointmentsInvoices
// } from 'src/slices/invoices';

import tenantMappings from 'src/utils/tenantMappings';

interface AppointmentState {
  isLoadingCustomerAppointments: boolean;
  isEditingAppointment: boolean;
  isCreatingAppointment: boolean;
  isCancellingAppointment: boolean;
  customerAppointments: any[];
  customerAppointmentDetail: any[];
  appointmentFreeSlots: any[];
  calendarSettings: any;
  apptErrorMessage: string;
  apptSuccessMessage: string;
}

const initialState: AppointmentState = {
  isLoadingCustomerAppointments: false,
  isEditingAppointment: false,
  isCreatingAppointment: false,
  isCancellingAppointment: false,
  customerAppointments: [],
  customerAppointmentDetail: [],
  appointmentFreeSlots: [],
  calendarSettings: {},
  apptErrorMessage: '',
  apptSuccessMessage: ''
};

const userMappings = tenantMappings?.userMappings;
const communicationMappings = tenantMappings?.communicationMappings;

// IDEA: Sort the array of appointments by start time DESCending first before rendering below
const sortCustomerAppointments = (a, b) => {
  if (a.appointmentStartTime > b.appointmentStartTime) {
    return -1;
  }
  if (a.appointmentStartTime < b.appointmentStartTime) {
    return 1;
  }
  return 0;
};

// IDEA: Sort the array of appointments by start time ASCending first before rendering below
const sortCustomerAppointmentsAsc = (a, b) => {
  if (a.appointmentStartTime < b.appointmentStartTime) {
    return -1;
  }
  if (a.appointmentStartTime > b.appointmentStartTime) {
    return 1;
  }
  return 0;
};

// Sort Ascending
const sortQueueAppointments = (a, b) => {
  if (a.appointmentStartTime < b.appointmentStartTime) {
    return -1;
  }
  if (a.appointmentStartTime > b.appointmentStartTime) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    getAppointment(state: AppointmentState, action: PayloadAction<any>) {
      if (action.payload) {
        state.customerAppointmentDetail = [action.payload];

        const apptIndex = state.customerAppointments.findIndex(
          (obj) => obj.id === action.payload.id
        );
        if (apptIndex >= 0) {
          state.customerAppointments = state.customerAppointments.map(
            (appt, i) => (i === apptIndex ? action.payload : appt)
          );
        }
      } else {
        state.customerAppointments = [{ id: 'Not Found' }];
      }
    },

    getCustomerAppointments(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      const customerAppointments = action.payload;
      customerAppointments.sort(sortCustomerAppointments);

      state.customerAppointments = customerAppointments;

      state.isLoadingCustomerAppointments = false;
    },

    createAppointment(state: AppointmentState, action: PayloadAction<any>) {
      const newAppointments = [action.payload, ...state.customerAppointments];
      newAppointments.sort(sortCustomerAppointments);

      state.customerAppointments = newAppointments;
    },

    editAppointment(state: AppointmentState, action: PayloadAction<any>) {
      const apptIndex = state.customerAppointments.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (apptIndex >= 0) {
        state.customerAppointments = state.customerAppointments.map((appt, i) =>
          i === apptIndex ? action.payload : appt
        );
      }
    },

    transferAppointment(state: AppointmentState, action: PayloadAction<any>) {
      state.customerAppointments = state.customerAppointments.filter(
        (appt) => appt.id !== action.payload.id
      );
    },

    setIsLoadingCustomerAppointments(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.isLoadingCustomerAppointments = action.payload;
    },

    setIsEditingCustomerAppointment(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.isEditingAppointment = action.payload;
    },

    setIsCreatingCustomerAppointment(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.isCreatingAppointment = action.payload;
    },

    setIsCancellingCustomerAppointment(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.isCancellingAppointment = action.payload;
    },

    listFreeAppointmentBookingSlots(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.appointmentFreeSlots = [...action.payload];
    },

    clearFreeAppointmentBookingSlots(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      state.appointmentFreeSlots = [];
    },

    storeApptCalendarSettings(
      state: AppointmentState,
      action: PayloadAction<any>
    ) {
      // state.calendarSettings = action.payload;

      const settings = action.payload?.content
        ? JSON.parse(action.payload?.content)
        : null;
      // console.log(settings?.calendars?.formOptions);
      state.calendarSettings = { ...settings?.calendars?.formOptions };
    },

    setErrorMessage(state: AppointmentState, action: PayloadAction<any>) {
      state.apptErrorMessage = action.payload;
    },

    setSuccessMessage(state: AppointmentState, action: PayloadAction<any>) {
      state.apptSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getAppointment =
  (appointmentId): AppThunk =>
  async (dispatch) => {
    // const response = await backOff(() => getAppointmentAPI(appointmentId), {
    //   numOfAttempts: 3
    // });
    // if (!response.customErrorMessage) {
    //   dispatch(slice.actions.getAppointment(response.data.getAppointment));
    // } else {
    //   dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    // }
  };

export const getCustomerAppointments =
  (patientId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerAppointments());

    // const response = await backOff(
    //   () => listPatientAppointmentsAPI(patientId),
    //   { numOfAttempts: 3 }
    // );

    // if (!response.customErrorMessage) {
    //   dispatch(
    //     slice.actions.getCustomerAppointments(response.data.listAppointments)
    //   );
    // } else {
    //   dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    // }

    return dispatch(stopLoadingCustomerAppointments());
  };

export const getCustomerAppointmentsByDateRange =
  (patientIds, appointmentStartDate, appointmentEndDate): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerAppointments());

    // const response = await backOff(
    //   () =>
    //     listPatientAppointmentsAPI(
    //       patientIds,
    //       appointmentEndDate,
    //       appointmentStartDate
    //     ),
    //   { numOfAttempts: 3 }
    // );

    // // console.log(response)
    // if (!response.customErrorMessage) {
    //   dispatch(
    //     slice.actions.getCustomerAppointments(response.data.listAppointments)
    //   );
    // } else {
    //   dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    // }

    return dispatch(stopLoadingCustomerAppointments());
  };

export const createAppointment =
  (
    tenantIdLowerCase,
    appointment,
    notify = true,
    quickAction = false
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startCreatingAppointment());
    dispatch(startLoadingCustomerAppointments());

    // const response = await createAppointmentAPI(appointment);
    // // console.log(response);

    // if (!response.customErrorMessage) {
    //   // updates the appointments array above
    //   dispatch(
    //     slice.actions.createAppointment(response.data.createAppointment)
    //   );

    //   // this updates the calendar too
    //   dispatch(updateEvent(response.data.createAppointment));

    //   if (notify) {
    //     if (response.data.createAppointment) {
    //       const data = response.data.createAppointment;

    //       const bookingEmailTemplate =
    //         communicationMappings?.createPatientSingleAppointmentEmailTemplate(
    //           tenantIdLowerCase,
    //           data
    //         );

    //       const emailResponse = await createCommunicationAPI({
    //         appointmentId: data?.id,
    //         body: bookingEmailTemplate,
    //         cc: [],
    //         bcc: [],
    //         destination: data?.patient.piiProfile.email,
    //         from: `booking+${userMappings?.getMappedTenantName(
    //           tenantIdLowerCase
    //         )}@gethelm.io`,
    //         fromName: data?.location?.name,
    //         medium: 'Email',
    //         patientId: data?.patient.id,
    //         replyTo: {
    //           email:
    //             data?.location?.emails[0] ||
    //             `contact+${userMappings?.getMappedTenantName(
    //               tenantIdLowerCase
    //             )}@gethelm.io`,
    //           name: data?.location?.name
    //         },
    //         subject: `Appointment Booking - ${format(
    //           parseISO(data?.appointmentStartTime),
    //           'EEEE, LLLL do, h:mm a'
    //         )}`,
    //         type: 'Html'
    //       });
    //     }
    //   }
    //   if (quickAction) {
    //     dispatch(
    //       slice.actions.setQuickActionSuccessMessage(
    //         'Appointment successfully created'
    //       )
    //     );
    //   } else {
    //     dispatch(
    //       slice.actions.setSuccessMessage('Appointment successfully created')
    //     );
    //   }
    // } else {
    //   if (quickAction) {
    //     dispatch(
    //       slice.actions.setQuickActionErrorMessage(response.customErrorMessage)
    //     );
    //   } else {
    //     dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    //   }
    // }

    const followUp = () => {
      dispatch(stopCreatingAppointment());
      dispatch(stopLoadingCustomerAppointments());
    };

    return followUp();
  };

export const editAppointment =
  (tenantIdLowerCase, appointment, notify = false, snackBar = true): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingAppointment());

    // const response = await editAppointmentAPI(appointment);

    // // console.log(response);

    // if (!response.customErrorMessage) {
    //   dispatch(slice.actions.editAppointment(response.data.updateAppointment));

    //   dispatch(updateEvent(response.data.updateAppointment));

    //   if (notify) {
    //     if (response.data.updateAppointment) {
    //       const data = response.data.updateAppointment;

    //       const bookingEmailTemplate =
    //         communicationMappings?.updatePatientSingleAppointmentEmailTemplate(
    //           tenantIdLowerCase,
    //           data
    //         );

    //       const emailResponse = await createCommunicationAPI({
    //         appointmentId: data?.id,
    //         body: bookingEmailTemplate,
    //         cc: [],
    //         bcc: [],
    //         destination: data?.patient.piiProfile.email,
    //         from: `booking+${userMappings?.getMappedTenantName(
    //           tenantIdLowerCase
    //         )}@gethelm.io`,
    //         fromName: data?.location?.name,
    //         medium: 'Email',
    //         patientId: data?.patient.id,
    //         replyTo: {
    //           email:
    //             data?.location?.emails[0] ||
    //             `contact+${userMappings?.getMappedTenantName(
    //               tenantIdLowerCase
    //             )}@gethelm.io`,
    //           name: data?.location?.name
    //         },
    //         subject: `Appointment Update - ${format(
    //           parseISO(data?.appointmentStartTime),
    //           'EEEE, LLLL do, h:mm a'
    //         )}`,
    //         type: 'Html'
    //       });
    //     }
    //   }
    //   if (snackBar) {
    //     dispatch(
    //       slice.actions.setSuccessMessage('Appointment successfully updated')
    //     );
    //   }
    // } else {
    //   dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    // }

    return dispatch(stopEditingAppointment());
  };

// export const editAppointmentNote =
//   (appointment): AppThunk =>
//   async (dispatch) => {
//     dispatch(startEditingAppointment());

//     const response = await editAppointmentAPI(appointment);

//     // console.log(response);

//     if (!response.customErrorMessage) {
//       dispatch(slice.actions.editAppointment(response.data.updateAppointment));

//       dispatch(updateEvent(response.data.updateAppointment));

//       dispatch(
//         slice.actions.setSuccessMessage(
//           'Appointment Notes successfully updated'
//         )
//       );
//     } else {
//       dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
//     }

//     return dispatch(stopEditingAppointment());
//   };

export const transferAppointment =
  (
    appointmentId,
    currentCustomerId,
    newCustomerId,
    cascadeInvoice?
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingAppointment());

    // const response = await transferAppointmentAPI(
    //   appointmentId,
    //   currentCustomerId,
    //   newCustomerId,
    //   cascadeInvoice
    // );

    // if (!response.customErrorMessage) {
    //   dispatch(
    //     slice.actions.transferAppointment(response.data.transferAppointment)
    //   );

    //   dispatch(getPatientInvoices(currentCustomerId));

    //   dispatch(
    //     slice.actions.setSuccessMessage('Appointment successfully transferred')
    //   );
    // } else {
    //   dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    // }

    return dispatch(stopEditingAppointment());
  };

export const confirmAppointment =
  (appointmentId, isConfirmed, newStatus?): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingAppointment());

    // const response = await confirmAppointmentAPI(
    //   appointmentId,
    //   isConfirmed,
    //   newStatus
    // );

    // if (!response.customErrorMessage) {
    //   dispatch(
    //     slice.actions.editAppointment(
    //       response.data.updateConfirmStatusAppointment
    //     )
    //   );

    //   dispatch(
    //     slice.actions.setSuccessMessage('Appointment successfully confirmed')
    //   );
    // } else {
    //   dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    // }

    return dispatch(stopEditingAppointment());
  };

export const checkinAppointment =
  (appointmentId, isCheckin, newStatus?): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingAppointment());

    // const response = await checkinAppointmentAPI(
    //   appointmentId,
    //   isCheckin,
    //   newStatus
    // );

    // if (!response.customErrorMessage) {
    //   dispatch(
    //     slice.actions.editAppointment(
    //       response.data.updateCheckinStatusAppointment
    //     )
    //   );
    // } else {
    //   dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    // }

    return dispatch(stopEditingAppointment());
  };

export const cancelAppointment =
  (
    tenantIdLowerCase,
    appointmentId,
    isCancelled,
    cancellationNote,
    unlinkAppointmentPlan,
    notify = false,
    bulkCancel = false
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startCancellingAppointment());
    dispatch(startLoadingCustomerAppointments());

    // const response = await cancelAppointmentAPI(
    //   appointmentId,
    //   isCancelled,
    //   cancellationNote,
    //   unlinkAppointmentPlan
    // );

    // if (!response.customErrorMessage) {
    //   dispatch(
    //     slice.actions.editAppointment(
    //       response.data.updateCancelStatusAppointment
    //     )
    //   );

    //   if (notify) {
    //     if (response.data.updateCancelStatusAppointment) {
    //       const data = response.data.updateCancelStatusAppointment;

    //       const bookingEmailTemplate =
    //         communicationMappings?.cancelPatientSingleAppointmentEmailTemplate(
    //           tenantIdLowerCase,
    //           data
    //         );

    //       const emailResponse = await createCommunicationAPI({
    //         appointmentId: data?.id,
    //         body: bookingEmailTemplate,
    //         cc: [],
    //         bcc: [],
    //         destination: data?.patient?.piiProfile.email,
    //         from: `booking+${userMappings?.getMappedTenantName(
    //           tenantIdLowerCase
    //         )}@gethelm.io`,
    //         fromName: data?.location?.name,
    //         medium: 'Email',
    //         patientId: data?.patient.id,
    //         replyTo: {
    //           email:
    //             data?.location?.emails[0] ||
    //             `contact+${userMappings?.getMappedTenantName(
    //               tenantIdLowerCase
    //             )}@gethelm.io`,
    //           name: data?.location?.name
    //         },
    //         subject: `Appointment Cancellation - ${format(
    //           parseISO(data?.appointmentStartTime),
    //           'EEEE, LLLL do, h:mm a'
    //         )}`,
    //         type: 'Html'
    //       });
    //     }
    //   }

    //   if (!bulkCancel) {
    //     dispatch(
    //       slice.actions.setSuccessMessage('Appointment successfully cancelled')
    //     );
    //   }
    // } else {
    //   dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    // }

    const followUp = () => {
      dispatch(stopCancellingAppointment());
      dispatch(stopLoadingCustomerAppointments());
    };

    return followUp();
  };

export const rescheduleAppointment =
  (
    tenantIdLowerCase,
    appointmentId,
    isCancelled,
    cancellationNote,
    newAppointment,
    notify = false,
    unlinkAppointmentPlan = false
  ): AppThunk =>
  async (dispatch) => {
    dispatch(startEditingAppointment());
    dispatch(startCancellingAppointment());
    dispatch(startCreatingAppointment());

    // const cancelResponse = await cancelAppointmentAPI(
    //   appointmentId,
    //   isCancelled,
    //   cancellationNote,
    //   unlinkAppointmentPlan
    // );

    // // console.log(cancelResponse);

    // if (!cancelResponse.customErrorMessage) {
    //   dispatch(
    //     slice.actions.editAppointment(
    //       cancelResponse.data.updateCancelStatusAppointment
    //     )
    //   );
    //   dispatch(updateEvent(cancelResponse.data.updateCancelStatusAppointment));
    // }

    // const response = await createAppointmentAPI(newAppointment);

    // if (!response.customErrorMessage) {
    //   dispatch(
    //     slice.actions.createAppointment(response.data.createAppointment)
    //   );

    //   // this updates the calendar too
    //   dispatch(updateEvent(response.data.createAppointment));

    //   if (notify) {
    //     if (response?.data?.createAppointment) {
    //       const data = response.data.createAppointment;

    //       const bookingEmailTemplate =
    //         communicationMappings?.reschedulePatientSingleAppointmentEmailTemplate(
    //           tenantIdLowerCase,
    //           data
    //         );

    //       const emailResponse = await createCommunicationAPI({
    //         appointmentId: data?.id,
    //         body: bookingEmailTemplate,
    //         cc: [],
    //         bcc: [],
    //         destination: data?.patient.piiProfile.email,
    //         from: `booking+${userMappings?.getMappedTenantName(
    //           tenantIdLowerCase
    //         )}@gethelm.io`,
    //         fromName: data?.location?.name,
    //         medium: 'Email',
    //         patientId: data?.patient.id,
    //         replyTo: {
    //           email:
    //             data?.location?.emails[0] ||
    //             `contact+${userMappings?.getMappedTenantName(
    //               tenantIdLowerCase
    //             )}@gethelm.io`,
    //           name: data?.location?.name
    //         },
    //         subject: `Appointment Update - ${format(
    //           parseISO(data?.appointmentStartTime),
    //           'EEEE, LLLL do, h:mm a'
    //         )}`,
    //         type: 'Html'
    //       });
    //     }
    //   }

    //   dispatch(
    //     slice.actions.setSuccessMessage('Appointment successfully rescheduled.')
    //   );
    // } else {
    //   dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    // }

    const followUp = () => {
      dispatch(stopEditingAppointment());
      dispatch(stopCancellingAppointment());
      dispatch(stopCreatingAppointment());
    };

    return followUp();
  };

// export const listFreeAppointmentBookingSlots =
//   (serviceIds, startDate, endDate): AppThunk =>
//   async (dispatch) => {
//     const response = await listFreeAppointmentBookingSlotsAPI(
//       serviceIds,
//       startDate,
//       endDate
//     );
//     // console.log(response)
//     if (!response.customErrorMessage) {
//       dispatch(
//         slice.actions.listFreeAppointmentBookingSlots(
//           response.data.listFreeAppointmentBookingSlots
//         )
//       );
//     } else {
//       dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
//     }
//   };

// export const clearFreeAppointmentBookingSlots =
//   (): AppThunk => async (dispatch) => {
//     dispatch(slice.actions.clearFreeAppointmentBookingSlots('clear'));
//   };

export const startLoadingCustomerAppointments =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCustomerAppointments(true));
  };

export const stopLoadingCustomerAppointments =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCustomerAppointments(false));
  };

export const startEditingAppointment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingCustomerAppointment(true));
};

export const stopEditingAppointment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsEditingCustomerAppointment(false));
};

export const startCreatingAppointment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingCustomerAppointment(true));
};

export const stopCreatingAppointment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCreatingCustomerAppointment(false));
};

export const startCancellingAppointment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCancellingCustomerAppointment(true));
};

export const stopCancellingAppointment = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsCancellingCustomerAppointment(false));
};

export const storeApptCalendarSettings = function (
  calendarSettings: any
): AppThunk {
  return (dispatch) => {
    dispatch(slice.actions.storeApptCalendarSettings(calendarSettings));
  };
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;
