import { useState, FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Card,
  Grid,
  Link,
  Tooltip,
  Typography,
  Container,
  Alert,
  styled
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import NewUserProfileForm from './NewUserProfileForm';
import { updateCustomerMinimalProfileAPI } from './api';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

function NewUserProfile({ customerLocationId }) {
  const { t }: { t: any } = useTranslation();

  const [errorMsg, setErrorMsg] = useState('');

  console.log('customerLocationId', customerLocationId);

  const onSubmit = async (data) => {
    console.log(data);
    const response = await updateCustomerMinimalProfileAPI(
      data.fullName.trim(),
      data.mobileCountryCode,
      data.mobile.trim(),
      customerLocationId
    );

    if (response?.data?.updateCustomerMinimalProfile) {
      // console.log('If success, then just refresh the page and move on ');
      window.location.reload();
    }
  };

  // Registering React Hook Form
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      fullName: '',
      mobileCountryCode: '65',
      mobile: ''
    }
  });

  if (!customerLocationId) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Your Profile</title>
      </Helmet>
      <MainContent>
        <TopWrapper>
          <Container maxWidth="sm">
            <Card
              sx={{
                mt: 3,
                px: 4,
                pt: 5,
                pb: 3
              }}
            >
              {/* <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Welcome')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('Please fill in your details.')}
                </Typography>
              </Box> */}

              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={0} rowGap={1}>
                  <Grid item xs={12}>
                    <NewUserProfileForm control={control} errors={errors} />
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      sx={{ mt: 2 }}
                    >
                      <Button
                        fullWidth
                        type="submit"
                        startIcon={
                          isSubmitting ? <CircularProgress size="1rem" /> : null
                        }
                        disabled={isSubmitting}
                        variant="contained"
                      >
                        {'Save'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>

              {errorMsg === '' ? null : (
                <Alert
                  sx={{ my: 2 }}
                  data-testid="profile-error-message"
                  severity="error"
                >
                  {errorMsg}
                </Alert>
              )}
            </Card>
          </Container>
        </TopWrapper>
      </MainContent>
    </>
  );
}

export default NewUserProfile;
