import { FC, ReactNode, useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import {
  Box,
  Dialog,
  Alert,
  Backdrop,
  CircularProgress,
  Snackbar,
  Stack,
  useTheme
} from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ThemeSettings from 'src/components/ThemeSettings';
import { Offline, Online } from 'react-detect-offline';

import Sidebar from './Sidebar';
import Header from './Header';

import { useLocation } from 'react-router-dom';
// import useAuth from 'src/hooks/useAuth';
import { useDispatch, useSelector } from 'src/store';

// import { getAllInventory, listInventoryOnLoad } from 'src/slices/inventories';
import { getLoggedInUser } from 'src/slices/users';

interface AccentHeaderLayoutProps {
  children?: ReactNode;
}

const AccentHeaderLayout: FC<AccentHeaderLayoutProps> = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { loggedInUser, userList } = useSelector((state) => state.users);
  const urlLocation = useLocation();
  const [isOffline, setIsOffline] = useState(false);

  let sxPadding = '';

  if (urlLocation.pathname.includes('/app/queue')) {
    sxPadding = '0';
  } else {
    sxPadding = theme.sidebar.width;
  }

  const auth = useAuth();
  const emailOwnerId = auth.user.id;

  useEffect(() => {
    if (emailOwnerId) {
      dispatch(getLoggedInUser(emailOwnerId));
    }

    // dispatch(listInventoryOnLoad());
    // dispatch(listUsersOnLoad());
    // dispatch(listContactsOnLoad());
  }, []);

  if (urlLocation.pathname.includes('/app/appt/book')) {
    return <Outlet />;
  }

  return (
    <>
      {isOffline && (
        <Alert
          severity="error"
          variant="filled"
          sx={{ width: '100%', zIndex: 15, borderRadius: 0 }}
        >
          Network offline. Please check your internet connection and refresh the
          page.
        </Alert>
      )}

      <Header />
      <Sidebar />

      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          flex: 1,
          display: 'flex',
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            pl: sxPadding
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Box flexGrow={1}>
            {loggedInUser?.type === 'None' ? null : (
              <>
                <Outlet />
              </>
            )}
          </Box>
        </Box>
        {/* <ThemeSettings /> */}
      </Box>
    </>
  );
};

export default AccentHeaderLayout;
