import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// import Amplify, { Auth } from 'aws-amplify';
import { Amplify, Auth } from 'aws-amplify';
import { amplifyConfig } from '../config';
import { User } from 'src/models/user';
import { Mixpanel } from './MixPanelContext';

const newConfig = {
  graphql_headers: async () => ({
    Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
  }),
  Auth: {
    identityPoolId: amplifyConfig.aws_cognito_identity_pool_id,
    region: amplifyConfig.aws_appsync_region,
    userPoolId: amplifyConfig.aws_user_pools_id,
    userPoolWebClientId: amplifyConfig.aws_user_pools_web_client_id
  },
  // Storage: {
  //   AWSS3: {
  //     bucket: 'helmclinic-document-bucket', //REQUIRED -  Amazon S3 bucket name
  //     region: 'ap-southeast-1' //OPTIONAL -  Amazon service region
  //   }
  // },
  ...amplifyConfig
};

Amplify.configure(newConfig);

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: any | null;
}

interface AuthContextValue extends AuthState {
  method: 'Amplify';
  login: (email: string) => Promise<void>;
  loginOTP: (user: any, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, password: string) => Promise<void>;
  verifyCode: (username: string, code: string) => Promise<void>;
  resendCode: (username: string) => Promise<void>;
  passwordRecovery: (username: string) => Promise<void>;
  passwordReset: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: any | null;
  };
};

type StartLoginAction = {
  type: 'START_LOGIN';
  payload: {
    user: any;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: any;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type VerifyCodeAction = {
  type: 'VERIFY_CODE';
};

type ResendCodeAction = {
  type: 'RESEND_CODE';
};
type PasswordRecoveryAction = {
  type: 'PASSWORD_RECOVERY';
};

type PasswordResetAction = {
  type: 'PASSWORD_RESET';
};

type Action =
  | InitializeAction
  | StartLoginAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | VerifyCodeAction
  | ResendCodeAction
  | PasswordRecoveryAction
  | PasswordResetAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },

  START_LOGIN: (state: AuthState, action: StartLoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      user
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: AuthState): AuthState => ({ ...state }),
  VERIFY_CODE: (state: AuthState): AuthState => ({ ...state }),
  RESEND_CODE: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_RECOVERY: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_RESET: (state: AuthState): AuthState => ({ ...state })
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Amplify',
  login: () => Promise.resolve(),
  loginOTP: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        // console.log(user);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.attributes['custom:customerId'],
              username: user.username,
              familyName: user.attributes.family_name,
              givenName: user.attributes.given_name,
              email: user?.attributes?.email,
              mobile: user?.attributes?.mobile,
              type: 'Admin',
              tenantId: user?.attributes['custom:tenantId'],
              tenantCode: user.attributes['custom:tenantCode'],
              tenantName: user.attributes['custom:tenantName']
              // posts: user.posts,
              // coverImg: user.coverImg,
              // followers: user.followers,
              // description: user.description
            }
          }
        });
      } catch (error) {
        // console.log(error);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string): Promise<void> => {
    // this is the ususal password login call
    // const user = await Auth.signIn(email, password);

    const user = await Auth.signIn(email.trim().toLowerCase());

    // console.log(user);

    if (user.challengeName !== 'CUSTOM_CHALLENGE') {
      console.error(`Can't login, "${user?.challengeName}" failed.`);
      return;
    }

    // Mixpanel.identify(user.attributes['custom:userId']);
    // Mixpanel.people.set({
    //   username: user.username,
    //   givenName: user.attributes.given_name,
    //   type: user.attributes['custom:userType'],
    //   tenantId: user.attributes['custom:tenantId']
    // });
    // Mixpanel.track('User Login');

    dispatch({
      type: 'START_LOGIN',
      payload: {
        user: user
      }
    });
  };

  const loginOTP = async (user: any, password: string): Promise<void> => {
    const userRes = await Auth.sendCustomChallengeAnswer(user, password);
    // console.log(userRes);

    if (userRes.challengeName) {
      console.error(`Can't login, "${userRes.challengeName}" failed.`);
      dispatch({
        type: 'START_LOGIN',
        payload: {
          user: userRes
        }
      });
      return;
    }

    // Mixpanel.identify(user.attributes['custom:userId']);
    // Mixpanel.people.set({
    //   username: user.username,
    //   givenName: user.attributes.given_name,
    //   type: user.attributes['custom:userType'],
    //   tenantId: user.attributes['custom:tenantId']
    // });
    // Mixpanel.track('User Login');

    // console.log(userRes);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          id: userRes?.attributes['custom:customerId'],
          username: userRes.username,
          familyName: userRes.attributes?.family_name,
          givenName: userRes.attributes?.given_name,
          email: userRes?.attributes?.email,
          mobile: userRes?.attributes?.mobile,
          type: 'Admin',
          tenantId: userRes?.attributes['custom:tenantId'],
          tenantCode: userRes?.attributes['custom:tenantCode'],
          tenantName: userRes?.attributes['custom:tenantName']
        }
      }
    });
  };

  const logout = async (): Promise<void> => {
    Mixpanel.reset();

    await Auth.signOut();
    window.location.reload();
    // window.location.href = `/`;
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (email: string, password: string): Promise<void> => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email }
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (
    username: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Amplify',
        login,
        loginOTP,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
