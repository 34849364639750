import { PartialRouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';

import { Navigate } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';

import baseRoutes from './base';
import overviewRoutes from './overview';
import appointmentRoutes from './appointments';
import invoiceRoutes from './invoices';
import profileRoutes from './profile';

import maintenanceRoutes from './maintenance';

import MaintenancePage from 'src/content/pages/Status/Maintenance';

const router: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: baseRoutes
  },

  // Accent Header Layout

  {
    path: 'app',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
      // <MaintenancePage />
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="overview" replace />
      },
      {
        path: 'overview',
        children: overviewRoutes
      },
      {
        path: 'appt',
        children: appointmentRoutes
      },
      {
        path: 'invoices',
        children: invoiceRoutes
      },
      {
        path: 'profile',
        children: profileRoutes
      }
    ]
  }
];

export default router;
