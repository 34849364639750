import { Alert, DialogContent, Typography, Switch, Grid } from '@mui/material';
import {
  HookFormAutocompleteField,
  HookFormCountryField,
  HookFormInputField
} from 'src/components/FormFields';

const NewUserProfileForm = ({ control, errors }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">Contact Details</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <HookFormInputField
            name="fullName"
            label={'Full Name'}
            control={control}
            errors={errors}
            rules={{
              required: true,
              validate: (v) => {
                return v.trim().length > 2 ? true : 'Full Name is too short.';
              }
            }}
            autoFocus={true}
          />
        </Grid>

        <Grid item xs={12} sm={7} md={7}>
          <HookFormCountryField
            name="mobileCountryCode"
            label={'Country Code'}
            control={control}
            errors={errors}
            result="phone"
            rules={{
              required: true
            }}
            notClearable={true}
          />
        </Grid>

        <Grid item xs={12} sm={5} md={5}>
          <HookFormInputField
            name="mobile"
            label={'Mobile'}
            control={control}
            errors={errors}
            rules={{
              required: true,
              pattern: {
                value: /^\d{8,12}$/,
                message: 'Please enter a valid mobile number.'
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NewUserProfileForm;
