export const customGetMyCustomerProfiles = /* GraphQL */ `
  query GetMyCustomerProfiles {
    getMyCustomerProfiles {
      id
      fullName
      preferredName
      birthday
      gender
      mobileCountryCode
      mobile
      email
      addressStreetAddress
      addressUnitNo
      addressCountry
      addressPostalCode
      telephone2
      telephone3
      # nextOfKin
      # createdAt
      # createdBy
      # updatedAt
      # updatedBy
    }
  }
`;
