import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { backOff } from 'exponential-backoff';
import { parseISO, isToday } from 'date-fns';

// import {
//   getInvoiceByIdAPI,
//   getInvoiceByNumberAPI,
//   listAllInvoicesAPI,
//   listAllCustomerInvoicesByIssueDateAPI,
// } from '../content/Invoices/api';
// import { listPatientPayments } from './payments';

// import { getPatientWallet } from 'src/slices/wallets';

interface InvoiceState {
  isLoadingCustomerInvoices: boolean;
  customerInvoices: any[];
  invoiceErrorMessage: string;
  invoiceSuccessMessage: string;
  // isSearchingInvoice: boolean;
  // isCreatingPayment: boolean;
  // invoiceSearchResults: any[];
}

const initialState: InvoiceState = {
  isLoadingCustomerInvoices: false,
  customerInvoices: [],
  invoiceErrorMessage: '',
  invoiceSuccessMessage: ''
  // isSearchingInvoice: false,
  // isCreatingPayment: false,
  // invoiceSearchResults: [],
};

const sortInvoicesDesc = (a, b) => {
  if (a.issueDate > b.issueDate) {
    return -1;
  }
  if (a.issueDate < b.issueDate) {
    return 1;
  }
  return 0;
};

const slice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    getCustomerInvoices(state: InvoiceState, action: PayloadAction<any>) {
      let invoices = [...action.payload];
      invoices.sort(sortInvoicesDesc);

      state.customerInvoices = invoices;

      state.isLoadingCustomerInvoices = false;
    },

    setIsLoadingCustomerInvoices(
      state: InvoiceState,
      action: PayloadAction<any>
    ) {
      state.isLoadingCustomerInvoices = action.payload;
    },

    setErrorMessage(state: InvoiceState, action: PayloadAction<any>) {
      state.invoiceErrorMessage = action.payload;
    },

    setSuccessMessage(state: InvoiceState, action: PayloadAction<any>) {
      state.invoiceSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getCustomerInvoices =
  (customerId): AppThunk =>
  async (dispatch) => {
    dispatch(startLoadingCustomerInvoices());

    // const response = await backOff(
    //   () => listAllInvoicesAPI(customerId),
    //   { numOfAttempts: 3 }
    // );

    // // console.log(response);

    // if (!response.customErrorMessage) {
    //   dispatch(slice.actions.getCustomerInvoices(response.data.listInvoices));
    // } else {
    //   dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    // }
    return dispatch(stopLoadingCustomerInvoices());
  };

export const startLoadingCustomerInvoices =
  (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoadingCustomerInvoices(true));
  };

export const stopLoadingCustomerInvoices = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingCustomerInvoices(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;
