import { combineReducers } from '@reduxjs/toolkit';

import { reducer as usersReducer } from 'src/slices/users';
import { reducer as appointmentsReducer } from 'src/slices/appointments';
import { reducer as invoicesReducer } from 'src/slices/invoices';

const rootReducer = combineReducers({
  users: usersReducer,
  appointments: appointmentsReducer,
  invoices: invoicesReducer
});

export default rootReducer;
