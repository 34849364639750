import * as Yup from 'yup';
import { FC, useState } from 'react';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  OutlinedInput,
  Checkbox,
  Typography,
  Link,
  IconButton,
  FormControlLabel,
  CircularProgress,
  InputAdornment,
  Tooltip
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

const LoginAmplify: FC<any> = ({ setErrorMsg, tenantId }) => {
  const { login, loginOTP, user } = useAuth() as any;
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();
  const [pendingOTP, setPendingOTP] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  const EMAIL_REGX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const parsed: any = Object.fromEntries(
    Object.entries(queryString.parse(location.search)).map(([k, v]) => [
      k.toLowerCase(),
      v
    ])
  );

  // const decodedEmail = parsed?.email ? decodeURIComponent(parsed?.email).toString() : ''
  const decodedEmail = parsed?.email;
  const decodedLocation = parsed?.location;

  return (
    <Formik
      initialValues={{
        email: decodedEmail || '',
        verificationCode: '',
        terms: true,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .max(150)
          .required(t('Please enter a valid email to proceed'))
          .matches(EMAIL_REGX, 'Please enter a valid email to proceed'),
        verificationCode: Yup.string().max(8),
        terms: Yup.boolean().oneOf(
          [true],
          t('You must agree to our terms and conditions')
        )
      })}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm, setFieldValue }
      ): Promise<void> => {
        try {
          if (pendingOTP) {
            // console.log(user);

            if (invalidUser) {
              // console.log('invalid')
              setStatus({ success: false });
              setSubmitting(false);
              setErrorMsg('Invalid OTP, please check and try again.');
              setFieldValue('verificationCode', '');
              return;
            }

            try {
              await loginOTP(user, values.verificationCode.toUpperCase());

              if (
                isMountedRef.current &&
                user?.signInUserSession?.accessToken
              ) {
                setStatus({ success: true });
                setSubmitting(false);
              } else {
                if (isMountedRef.current) {
                  console.log('fail');
                  setStatus({ success: false });
                  setSubmitting(false);
                  setErrorMsg('Invalid OTP, please check and try again.');
                  setFieldValue('verificationCode', '');
                }
              }
            } catch (err) {
              console.log(err);
              setStatus({ success: false });
              setSubmitting(false);
              resetForm();
              setPendingOTP(false);
              setErrorMsg(
                'Too many failed attempts. Please try again in 1 min.'
              );
            }
          } else {
            try {
              // Call Axios and Create User if does not exist first. we need to await and then once done, call login.
              // <<Axios call here>>

              const createInput = {
                tenantCode: tenantId.trim().toLowerCase(),
                email: values.email.trim().toLowerCase(),
                locationId: decodedLocation?.trim()?.toLowerCase() ?? null
              };
              const graphQLmutation = {
                query: `mutation CreateCustomerIfNotExist($input: CreateCustomerIfNotExistInput!) {\n  createCustomerIfNotExist(input: $input)  \n}\n`,
                variables: {
                  input: createInput
                }
              };

              const response = await axios.post(
                process.env.REACT_APP_AWS_GRAPHQL_API,
                graphQLmutation,
                {
                  headers: {
                    'x-api-key': process.env.REACT_APP_AWS_GRAPHQL_API_KEY,
                    accept: 'application/json, text/plain, */*',
                    'accept-language': 'en-GB,en;q=0.9,en-US;q=0.8,vi;q=0.7',
                    'content-type': 'application/json'
                  }
                }
              );

              // console.log(response);

              // Call Login after User has been created.
              await login(
                `${tenantId.trim().toLowerCase()}§${values.email
                  .trim()
                  .toLowerCase()}`
              );
              setInvalidUser(false);
            } catch (e) {
              setInvalidUser(true);
              console.log(e);
            }

            if (isMountedRef.current) {
              setPendingOTP(true);
              setStatus({ success: true });
              setSubmitting(false);
            }
          }
        } catch (err) {
          setErrorMsg('Something went wrong. Please try again later');
          console.error(err);

          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            data-testid="helm-username"
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            placeholder="Enter your email address.."
            helperText={touched.email && errors.email}
            label={t('Email')}
            disabled={pendingOTP}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            InputProps={{
              endAdornment: pendingOTP && (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    color="primary"
                    size="small"
                    disabled={!pendingOTP}
                    onClick={() => {
                      setPendingOTP(false);
                      setFieldValue('verificationCode', '');
                    }}
                  >
                    <EditTwoToneIcon /> Edit
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          {pendingOTP && (
            <TextField
              data-testid="helm-verification-code"
              error={Boolean(
                touched.verificationCode && errors.verificationCode
              )}
              fullWidth
              margin="normal"
              helperText={touched.verificationCode && errors.verificationCode}
              label={t('Verification Code')}
              name="verificationCode"
              onBlur={handleBlur}
              onChange={handleChange}
              type={'text'}
              value={values.verificationCode}
              variant="outlined"
            />
          )}

          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={values.terms}
                  name="terms"
                  color="primary"
                  onChange={handleChange}
                />
              }
              label={
                <>
                  <Typography variant="body2">
                    {t('I accept the')}{' '}
                    <Link
                      component="a"
                      href="https://gethelm.io/terms-of-use"
                      target="_blank"
                      rel="noopener"
                    >
                      {t('terms and conditions')}
                    </Link>
                    .
                  </Typography>
                </>
              }
            /> */}
            {/* <Link component={RouterLink} to="/account/recover-password">
              <b>{t('Lost password?')}</b>
            </Link> */}
          </Box>

          {Boolean(touched.terms && errors.terms) && (
            <FormHelperText error>{errors.terms}</FormHelperText>
          )}

          {pendingOTP && (
            <FormHelperText error>
              {'Please check your email for a verification code.'}
            </FormHelperText>
          )}

          <Button
            data-testid="login-submit-button"
            sx={{
              mt: 3
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {pendingOTP ? t('Sign in') : t('Continue with email')}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default LoginAmplify;
