import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const Appointments = Loader(lazy(() => import('src/content/Appointments')));
const Booking = Loader(lazy(() => import('src/content/Booking')));
// const SinglePatient = Loader(lazy(() => import('src/content/Patients/single')));

const appointmentRoutes = [
  {
    path: '/',
    element: <Navigate to="list" replace />
  },
  {
    path: 'list',
    element: <Appointments />
  },
  {
    path: 'book',
    element: <Booking />
  }
];

export default appointmentRoutes;
