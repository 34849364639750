export const getQueueRightSideTabs = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = [
    { value: 'personal', label: 'Personal' },
    { value: 'memo', label: 'Memo' }
  ];

  try {
    const rightSideTabs = {
      cityosteophysio: [
        { value: 'personal', label: 'Personal' },
        { value: 'medical', label: 'Medical' },
        { value: 'memo', label: 'Memo' }
      ],
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': [
        { value: 'personal', label: 'Personal' },
        { value: 'medical', label: 'Medical' },
        { value: 'memo', label: 'Memo' }
      ],
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': [
        { value: 'personal', label: 'Personal' },
        { value: 'memo', label: 'Memo' }
      ],
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': [
        { value: 'personal', label: 'Personal' },
        { value: 'medical', label: 'Medical' },
        { value: 'memo', label: 'Memo' }
      ],
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': [
        { value: 'personal', label: 'Personal' },
        { value: 'medical', label: 'Medical' },
        { value: 'memo', label: 'Memo' }
      ],

      // Dev
      '9e3ae757-b4b1-464a-b6b9-9e7005e1f550': [
        { value: 'personal', label: 'Personal' },
        { value: 'medical', label: 'Medical' },
        { value: 'memo', label: 'Memo' }
      ],
      '46ef088a-d044-4000-8a53-578e6be74ef1': [
        { value: 'personal', label: 'Personal' },
        { value: 'medical', label: 'Medical' },
        { value: 'memo', label: 'Memo' }
      ]
    };

    const result = rightSideTabs[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getQueueTopBarOptions = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = ['Time Chit'];

  try {
    const topBarOptions = {
      cityosteophysio: ['Dry Needling Consent', 'Time Chit'],
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': [],
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': ['Time Chit'],
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': ['Time Chit'],
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': ['Time Chit']
    };

    const result = topBarOptions[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getQueueStrictlyInvoiceOptions = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = false;

  try {
    const queueStrictlyInvoiceOptions = {
      cityosteophysio: true,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': false,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': false,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': false,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': false
    };

    const result = queueStrictlyInvoiceOptions[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getQueueArriveOption = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = false;

  try {
    const queueArriveOption = {
      cityosteophysio: true,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': false,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': false,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': true,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': false,

      // Dev
      '9e3ae757-b4b1-464a-b6b9-9e7005e1f550': true,
      '46ef088a-d044-4000-8a53-578e6be74ef1': true
    };

    const result = queueArriveOption[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getQueueOperationsViewNotesOption = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = false;

  try {
    const queueOperationsViewNotesOption = {
      cityosteophysio: false,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': false,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': false,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': true,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': false
    };

    const result = queueOperationsViewNotesOption[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getQueueNotesInitialLimit = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = 5;

  try {
    const queueNotesInitialLimit = {
      cityosteophysio: 5,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': 0,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': 5,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': 5,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': 5
    };

    const result = queueNotesInitialLimit[tenantIdLowerCase];

    if (result !== undefined) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};
