export const getPaymentModeLabel = (paymentMode: string): String => {
  try {
    const map = {
      Amex: {
        text: 'Amex'
      },
      BankTransfer: {
        text: 'Bank Transfer'
      },
      Cash: {
        text: 'Cash'
      },
      CdcVoucher: {
        text: 'CDC Voucher'
      },
      Cheque: {
        text: 'Cheque'
      },
      DbsIpp: {
        text: 'DBS IPP'
      },
      DinersClub: {
        text: 'Diners Club'
      },
      Discover: {
        text: 'Discover'
      },
      Giro: {
        text: 'Giro'
      },
      GrabPay: {
        text: 'GrabPay'
      },
      Hoolah: {
        text: 'Hoolah'
      },
      Insurance: {
        text: 'Insurance'
      },
      Mastercard: {
        text: 'Mastercard'
      },
      Nets: {
        text: 'Nets'
      },
      OcbcIpp: {
        text: 'OCBC IPP'
      },
      PayNow: {
        text: 'PayNow'
      },
      PayPal: {
        text: 'PayPal'
      },
      ShopeePay: {
        text: 'ShopeePay'
      },
      UnionPay: {
        text: 'UnionPay'
      },
      UobIpp: {
        text: 'UOB IPP'
      },
      Visa: {
        text: 'Visa'
      },
      VisaMastercard: {
        text: 'Visa / Mastercard'
      },
      Voucher: {
        text: 'Voucher'
      },
      Wallet: {
        text: 'Wallet'
      },
      Others: {
        text: 'Others'
      },
      Stripe: {
        text: 'HelmPay'
      },
      Total: {
        text: 'Total'
      }
    };

    const { text }: any = map[paymentMode];

    return text;
  } catch {
    return 'Others';
  }
};

export const getPaymentTypeLabel = (paymentType: string): String => {
  try {
    const map = {
      Invoice: {
        text: 'Invoice Payment'
      },
      TopUpWallet: {
        text: 'Deposit'
      },
      WithdrawWallet: {
        text: 'Withdrawal'
      }
    };

    const { text }: any = map[paymentType];

    return text;
  } catch {
    return 'Unknown';
  }
};

export const getPaymentStatusLabel = (
  paymentStatus: string
): { text: string; color: string } => {
  try {
    const map = {
      Success: {
        text: 'Success',
        color: 'success'
      },
      Fail: {
        text: 'Fail',
        color: 'error'
      },
      Pending: {
        text: 'Pending',
        color: 'secondary'
      }
    };

    const { text, color }: any = map[paymentStatus];

    return { text, color };
  } catch {
    return { text: 'UNKNOWN', color: 'error' };
  }
};
