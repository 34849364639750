/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomerIfNotExist = /* GraphQL */ `
  mutation CreateCustomerIfNotExist($input: CreateCustomerIfNotExistInput!) {
    createCustomerIfNotExist(input: $input)
  }
`;
export const updateCustomerMinimalProfile = /* GraphQL */ `
  mutation UpdateCustomerMinimalProfile(
    $input: UpdateCustomerMinimalProfileInput!
  ) {
    updateCustomerMinimalProfile(input: $input)
  }
`;
export const createOnlineBookingTransaction = /* GraphQL */ `
  mutation CreateOnlineBookingTransaction(
    $input: CreateOnlineBookingTransactionInput!
  ) {
    createOnlineBookingTransaction(input: $input) {
      id
      onlineBookingPageId
      patientName
      patientEmail
      patientMobile
      serviceId
      locationId
      startTime
      endTime
      patientId
      appointmentId
      originalPrice {
        amount
        currency
        precision
        scale
      }
      adjustmentCodes {
        id
        type
        startTime
        endTime
      }
      promoCodeAdjustments {
        id
        type
        reference
        appliedPromoCode
      }
      paymentDue {
        amount
        currency
        precision
        scale
      }
      status
      paymentRequired
      paymentStatus
      stripeChargeIds
      customFields
      urlQueryParams
      communicationId
      paymentIds
      paidAt
      confirmedAt
      confirmedBy
      createdAt
      createdBy
      updatedAt
      updatedBy
      voidedAt
      voidedBy
      voidReason
      expiredAt
      stripeIntegrated
      stripeSetupIntentUrl
      multiplePatients
    }
  }
`;
