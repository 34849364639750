export const getMappedTenantName = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = tenantIdLowerCase;

  try {
    const mappedTenantNames = {
      cityosteophysio: 'cityosteophysio',
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': 'qmenswear',
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': 'sgtittar',
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': 'chirosg',
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': 'keiki',

      // Dev
      'a75b6463-f88f-4299-ad5d-b184eeae0513': 'helm',
      '5f4c2624-c3e2-41f8-947a-294ed64e9dcf': 'restorationroom',
      // 'd8bdf251-a884-430d-8e94-3f189b20c70a': 'raphaphysiotherapy',
      '9e3ae757-b4b1-464a-b6b9-9e7005e1f550': 'therehablab',
      '46ef088a-d044-4000-8a53-578e6be74ef1': 'jomedical'
    };

    const result = mappedTenantNames[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getPublicTenantName = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = tenantIdLowerCase;

  try {
    const mappedTenantNames = {
      cityosteophysio: 'City Osteopathy & Physiotherapy',
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': 'Q Menswear',
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': 'SG Tit Tar',
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': 'Chiropractic Singapore',
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': 'Keiki Speech Therapy',

      // Dev
      'a75b6463-f88f-4299-ad5d-b184eeae0513': 'Helm Labs',
      '5f4c2624-c3e2-41f8-947a-294ed64e9dcf': 'The Restoration Room',
      // 'd8bdf251-a884-430d-8e94-3f189b20c70a': 'Rapha Physiotherapy',
      '9e3ae757-b4b1-464a-b6b9-9e7005e1f550': 'The Rehab Lab',
      '46ef088a-d044-4000-8a53-578e6be74ef1': 'Jo Medical Clinic'
    };

    const result = mappedTenantNames[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getTeamReportingLines = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {};

  try {
    const mappedTeamReportingLines = {
      cityosteophysio: {
        'engsoo.ong': [
          'engsoo.ong',
          'rachal.quinlan',
          'xueting.lim',
          'naveendran.myeluvaganam',
          'marianne.fischer',
          'ewa.kunik',
          'tyler.koh',
          'darrion.lim',
          'wanying.teh',
          'sunly.hout',
          'cesar.seguban',
          'aparna.shah'
        ],
        'arthur.codsi': [
          'arthur.codsi',
          'janmark.smith',
          'bradley.blair',
          'grace.yap',
          'haseeb.ahmed',
          'paulcristian.stoenescu',
          'terence.tong',
          'emma.bundle'
        ],
        'wanhui.yip': [
          'wanhui.yip',
          'ewa.kunik',
          'angie.wong',
          'madeline.soh',
          'jolinna.chan',
          'yanni.tan',
          'darrion.lim',
          'wanying.teh',
          'shimpi',
          'sunly.hout'
        ]
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        'ryan.vandenheuvel': ['ryan.vandenheuvel', 'emily.low']
      }
    };

    const result = mappedTeamReportingLines[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getFinanceTeamAccess = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = [];

  try {
    const mappedTenantNames = {
      cityosteophysio: ['siti.nurliyana@cityosteophysio.gethelm.io'],
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': [
        'donna.bentulan@chirosg.gethelm.io',
        'michelle.lee@chirosg.gethelm.io'
      ]
    };

    const result = mappedTenantNames[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getRoleDetails = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {};

  try {
    const mappedRoles = {
      cityosteophysio: {
        Practitioner: {
          userType: 'Practitioner',
          roleId: '7f490ca6-a21f-46b5-ac28-f45c82205a91'
        },
        'Clinical Lead': {
          userType: 'Practitioner',
          roleId: '16d3b2c3-fe6d-400b-aae8-5d8d5e3b15c3'
        },
        Operations: {
          userType: 'Operations',
          roleId: 'b87da3bc-cc64-4e17-b30a-8b173879c1c2'
        },
        'Operations Manager': {
          userType: 'Operations',
          roleId: 'dc809381-71ea-4122-9775-4b07e0766a4d'
        },
        Support: {
          userType: 'Operations',
          roleId: '8175f45f-d8bb-443a-9893-cd60cde4a4fc'
        },
        Finance: {
          userType: 'Operations',
          roleId: 'db789ff5-9cea-410f-be6d-1dba008c9af8'
        },
        Admin: {
          userType: 'Admin',
          roleId: 'b6f5285b-b4c2-4dc6-8184-f41c49cd8f2f'
        }
      },
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': {
        Practitioner: {
          userType: 'Practitioner',
          roleId: '272840ef-67aa-4e5b-8177-d2015e3e177a'
        },
        Admin: {
          userType: 'Admin',
          roleId: '8ef7fab8-60f6-4507-ae44-e613bd675b8b'
        },
        Operations: {
          userType: 'Operations',
          roleId: 'fcd87a6f-5335-47f8-9d2d-f32d6e825661'
        }
      },
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': {
        Practitioner: {
          userType: 'Practitioner',
          roleId: '16510693-7621-4ac2-ab8b-0469b8b15420'
        },
        Admin: {
          userType: 'Admin',
          roleId: '102ec04e-e67d-4f17-a71b-534e332934ba'
        }
      },
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': {
        Practitioner: {
          userType: 'Practitioner',
          roleId: '566fb051-e904-4719-b4f1-ceac45679d47'
        },
        Admin: {
          userType: 'Admin',
          roleId: '5ed7150c-d3d4-4697-9524-a1dc373f1b20'
        },
        Operations: {
          userType: 'Operations',
          roleId: '6e4061ef-ae70-4ef9-9654-4c9a5ef6c58d'
        },
        'Operations Manager': {
          userType: 'Operations',
          roleId: 'f5198e0e-3a0e-4c9b-b6d8-54b3c1f6487c'
        },
        Support: {
          userType: 'Operations',
          roleId: 'bae6895c-52ca-46e5-bb18-425d4b2ea01f'
        },
        Finance: {
          userType: 'Operations',
          roleId: '1f7ecb1e-8676-42e9-b946-54e3b8b5d883'
        }
      },
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': {
        Practitioner: {
          userType: 'Practitioner',
          roleId: '01beca88-64a6-43c5-85b3-2fcdbeb40408'
        },
        Admin: {
          userType: 'Admin',
          roleId: 'eb269085-d7ec-4f64-a089-9cc2b37106e4'
        },
        Operations: {
          userType: 'Operations',
          roleId: ''
        }
      }
      // '5f4c2624-c3e2-41f8-947a-294ed64e9dcf': {
      //   Practitioner: {
      //     userType: 'Practitioner',
      //     roleId: '65f7bc8d-b552-4a5f-9413-aeb9ba535e08'
      //   }
      // }
    };

    const result = mappedRoles[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};
