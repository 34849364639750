import { useState, FC, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  Tooltip,
  Typography,
  Container,
  Alert,
  styled
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
// import Auth0Login from '../LoginAuth0';
// import FirebaseAuthLogin from '../LoginFirebaseAuth';
// import JWTLogin from '../LoginJWT';
import AmplifyLogin from '../LoginAmplify';
import { useTranslation } from 'react-i18next';
import Logo from 'src/components/LogoSign';

const CardImg = styled(Card)(
  ({ theme }) => `
    width: 90px;
    height: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: ${theme.colors.alpha.white[100]};
    margin: 0 ${theme.spacing(1)};
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['all'])};

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

interface LoginBasicProps {
  tenantId?: any;
}

const LoginBasic: FC<LoginBasicProps> = ({ tenantId = 'firstLoad' }) => {
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (tenantId === '') {
      return navigate(`/${location.pathname.split('/')[1]}/status/error`);
    }
  }, [tenantId]);

  return (
    <>
      <Helmet>
        <title>Helm - Login</title>
      </Helmet>
      <MainContent>
        <TopWrapper>
          <Container maxWidth="sm">
            {/* <Logo /> */}
            <Card
              sx={{
                mt: 3,
                px: 4,
                pt: 5,
                pb: 3
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Sign in')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('Enter your email address to get started.')}
                </Typography>
              </Box>
              {/* {method === 'Auth0' && <Auth0Login />}
              {method === 'FirebaseAuth' && <FirebaseAuthLogin />}
              {method === 'JWT' && <JWTLogin />} */}
              {method === 'Amplify' && (
                <AmplifyLogin setErrorMsg={setErrorMsg} tenantId={tenantId} />
              )}
              {/* <Box my={4}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {t('Don’t have an account, yet? Drop us a note at')}
                </Typography>{' '}
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="#2844ac"
                  fontWeight="bold"
                  fontStyle="italic"
                >
                  {t('hello@gethelm.io')}
                </Typography>
                <Link component={RouterLink} to="/account/register-basic">
                  <b>Sign up here</b>
                </Link>
              </Box> */}
              {/* {method !== 'Auth0' && (
                <Tooltip
                  title={t('Used only for the live preview demonstration !')}
                >
                  <Alert severity="warning">
                    Use <b>demo@example.com</b> and password <b>TokyoPass1@</b>
                  </Alert>
                </Tooltip>
              )} */}

              {errorMsg === '' ? null : (
                <Alert
                  sx={{ my: 2 }}
                  data-testid="login-error-message"
                  severity="error"
                >
                  {errorMsg}
                </Alert>
              )}
            </Card>
          </Container>
        </TopWrapper>
      </MainContent>
    </>
  );
};

export default LoginBasic;
