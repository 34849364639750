import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import HomeIcon from '@mui/icons-material/Home';
import PaidIcon from '@mui/icons-material/Paid';
import GroupsIcon from '@mui/icons-material/Groups';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import TodayIcon from '@mui/icons-material/Today';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import PermPhoneMsgTwoToneIcon from '@mui/icons-material/PermPhoneMsgTwoTone';
import SmsTwoToneIcon from '@mui/icons-material/SmsTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import RecentActorsTwoToneIcon from '@mui/icons-material/RecentActorsTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import BookOnlineTwoToneIcon from '@mui/icons-material/BookOnlineTwoTone';
import HealingTwoToneIcon from '@mui/icons-material/HealingTwoTone';
import AutoStoriesTwoToneIcon from '@mui/icons-material/AutoStoriesTwoTone';
import TimerOffTwoToneIcon from '@mui/icons-material/TimerOffTwoTone';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import ConfirmationNumberTwoToneIcon from '@mui/icons-material/ConfirmationNumberTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import SchemaTwoToneIcon from '@mui/icons-material/SchemaTwoTone';
import ModelTrainingTwoToneIcon from '@mui/icons-material/ModelTrainingTwoTone';

export const getSideMenu = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = {
    admin: [
      {
        heading: 'General',
        items: [
          {
            name: 'Home',
            icon: HomeIcon,
            link: '/app/overview/home'
          },

          {
            name: 'Appointments',
            icon: AccessTimeTwoToneIcon,
            link: '/app/appt'
          },

          {
            name: 'Invoices',
            icon: ReceiptTwoToneIcon,
            link: '/app/invoices'
            // items: [
            //   {
            //     name: 'Listing',
            //     icon: ReceiptTwoToneIcon,
            //     link: '/app/invoices/list'
            //   },
            //   {
            //     name: 'Search',
            //     icon: SearchTwoToneIcon,
            //     link: '/app/invoices/search'
            //   }
            // ]
          },

          {
            name: 'Profile',
            icon: VpnKeyTwoToneIcon,
            link: '/app/profile'
          }
        ]
      }
    ]
  };

  return fallback;
};
