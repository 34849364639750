import {
  getMappedTenantName,
  getPublicTenantName,
  getTeamReportingLines,
  getFinanceTeamAccess,
  getRoleDetails
} from './userMappings';
import {
  getCalendarViewOptions,
  getCalendarSlotDuration
} from './calendarMappings';
import { getSideMenu } from './menuMappings';
import {
  getOnlineBookingWindowPeriod,
  getWarningMessage,
  getOptionalFieldsToShow,
  getFieldLabels,
  getFieldMandatoryRequirements
} from './onlineBookingMappings';
import { getInventorySectionsToShow } from './inventoryMappings';
import {
  getImageNoteOptions,
  getStructuredNoteOptions,
  getAllowEditAfterPublish,
  getAllowEditByOthers,
  getPinnedNoteTypes,
  getFlowProviderScreenBriefNoteKeys,
  getCompactNotesType,
  getImageDocsOfNoteType,
  getImageDocsFileName
} from './noteMappings';
import {
  getQueueRightSideTabs,
  getQueueTopBarOptions,
  getQueueStrictlyInvoiceOptions,
  getQueueArriveOption,
  getQueueOperationsViewNotesOption,
  getQueueNotesInitialLimit
} from './queueMappings';
import {
  getCustomerSectionsToShow,
  getCustomerFieldsToShow,
  getCustomerFieldMandatoryRequirement,
  getTerminalSectionsToShow,
  getTerminalCustomerFieldsToShow,
  getTerminalCustomerFieldMandatoryRequirement,
  getPublicIntakeSectionsToShow,
  getPublicIntakeCustomerFieldsToShow,
  getPublicIntakeCustomerFieldMandatoryRequirement,
  getMembershipTierDetails,
  getQuickActionCustomerOptionalFieldsToShow,
  getCustomerFieldLabels,
  useCustomerPreferredNameInTerminal
} from './patientMappings';
import {
  getRegistrationConsent,
  getDryNeedlingConsent
} from './consentMappings';
import {
  createOnlineBookingSuccessEmailTemplate,
  createPatientSingleAppointmentEmailTemplate,
  updatePatientSingleAppointmentEmailTemplate,
  cancelPatientSingleAppointmentEmailTemplate,
  reschedulePatientSingleAppointmentEmailTemplate,
  addCardEmailTemplate,
  sendPublicRegistrationEmailTemplate,
  getHandleAppointmentConfirmationEmailTemplate,
  getHandleAppointmentConfirmationSMSTemplate
} from './communicationMappings';
import { getMappedWordToUse } from './dictionaryMappings';
import {
  getDefaultCustomerPaymentOption,
  getDefaultCorporatePaymentOption
} from './paymentMappings';
import {
  getPrefillServiceDateInItemDescriptionOption,
  getDisplayIdentityNumber,
  getDisplayProvider,
  getDisplayEmployer,
  getDisplayLogo,
  getAccountingLockDate
} from './invoiceMappings';
import { getTerminalFeedbackQuestions } from './feedbackMappings';
import {
  getAllowRescheduleOption,
  getDefaultRescheduleCancellationOption
} from './appointmentMappings';
import { getTerminalPin } from './terminalMappings';
import { showInvoiceCharts, showPaymentCharts } from './dashboardMappings';
import {
  isFlowIntegrated,
  isGoogleCalendarIntegrated
} from './integrationMappings';

const tenantMappings = {
  userMappings: {
    getMappedTenantName,
    getPublicTenantName,
    getTeamReportingLines,
    getFinanceTeamAccess,
    getRoleDetails
  },
  calendarMappings: {
    getCalendarViewOptions,
    getCalendarSlotDuration
  },
  menuMappings: { getSideMenu },
  onlineBookingMappings: {
    getOnlineBookingWindowPeriod,
    getWarningMessage,
    getOptionalFieldsToShow,
    getFieldLabels,
    getFieldMandatoryRequirements
  },
  inventoryMappings: { getInventorySectionsToShow },
  noteMappings: {
    getImageNoteOptions,
    getStructuredNoteOptions,
    getAllowEditAfterPublish,
    getAllowEditByOthers,
    getPinnedNoteTypes,
    getFlowProviderScreenBriefNoteKeys,
    getCompactNotesType,
    getImageDocsOfNoteType,
    getImageDocsFileName
  },
  queueMappings: {
    getQueueRightSideTabs,
    getQueueTopBarOptions,
    getQueueStrictlyInvoiceOptions,
    getQueueArriveOption,
    getQueueOperationsViewNotesOption,
    getQueueNotesInitialLimit
  },
  patientMappings: {
    getCustomerSectionsToShow,
    getCustomerFieldsToShow,
    getCustomerFieldMandatoryRequirement,
    getTerminalSectionsToShow,
    getTerminalCustomerFieldsToShow,
    getTerminalCustomerFieldMandatoryRequirement,
    getPublicIntakeSectionsToShow,
    getPublicIntakeCustomerFieldsToShow,
    getPublicIntakeCustomerFieldMandatoryRequirement,
    getMembershipTierDetails,
    getQuickActionCustomerOptionalFieldsToShow,
    getCustomerFieldLabels,
    useCustomerPreferredNameInTerminal
  },
  consentMappings: { getRegistrationConsent, getDryNeedlingConsent },
  communicationMappings: {
    createOnlineBookingSuccessEmailTemplate,
    createPatientSingleAppointmentEmailTemplate,
    updatePatientSingleAppointmentEmailTemplate,
    cancelPatientSingleAppointmentEmailTemplate,
    reschedulePatientSingleAppointmentEmailTemplate,
    addCardEmailTemplate,
    sendPublicRegistrationEmailTemplate,
    getHandleAppointmentConfirmationEmailTemplate,
    getHandleAppointmentConfirmationSMSTemplate
  },
  dictionaryMappings: {
    getMappedWordToUse
  },
  paymentMappings: {
    getDefaultCustomerPaymentOption,
    getDefaultCorporatePaymentOption
  },
  invoiceMappings: {
    getPrefillServiceDateInItemDescriptionOption,
    getDisplayIdentityNumber,
    getDisplayProvider,
    getDisplayEmployer,
    getDisplayLogo,
    getAccountingLockDate
  },
  feedbackMappings: {
    getTerminalFeedbackQuestions
  },
  appointmentMappings: {
    getAllowRescheduleOption,
    getDefaultRescheduleCancellationOption
  },
  terminalMappings: {
    getTerminalPin
  },
  dashboardMappings: {
    showInvoiceCharts,
    showPaymentCharts
  },
  integrationMappings: {
    isFlowIntegrated,
    isGoogleCalendarIntegrated
  }
};

export default tenantMappings;
