import { FC, ReactNode, useState, useEffect } from 'react';
import AES from 'crypto-js/aes';
import { enc } from 'crypto-js';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { useSelector } from 'src/store';
import Login from 'src/content/pages/Auth/Login/Basic';
import queryString from 'query-string';
// import { checkForIndexHTMLChange } from './checkForIndexHTMLChange';
import NewUserProfile from 'src/content/Users/NewUserProfile';

interface AuthenticatedProps {
  children: ReactNode;
}

// const IndexHTMLURL = 'https://app.gethelm.io/';
// let reloadOnNextChange = false;

const decryptData = (str) => {
  const decodedStr = decodeURIComponent(str);
  return AES.decrypt(decodedStr, 'secretPassphrase').toString(enc.Utf8);
};

const Authenticated: FC<AuthenticatedProps> = (props: { children: any }) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const { loggedInUser } = useSelector((state) => state.users);
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  const parsed = Object.fromEntries(
    Object.entries(queryString.parse(location.search)).map(([k, v]) => [
      k.toLowerCase(),
      v
    ])
  );

  const decryptedData = parsed?.session
    ? JSON.parse(decryptData(parsed?.session))
    : null;

  // If in prod, detect for changes in app version
  // useEffect(() => {
  //   if (process.env.NODE_ENV === 'production') {
  //     let checkIndexInterval = setInterval(async () => {
  //       reloadOnNextChange = await checkForIndexHTMLChange(IndexHTMLURL);
  //       // console.log(reloadOnNextChange);
  //       if (reloadOnNextChange) {
  //         clearInterval(checkIndexInterval);
  //       }
  //     }, 1000 * 60 * 60);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (reloadOnNextChange) {
  //     window.location.reload();
  //   }
  // }, [location]);

  // console.log(loggedInUser)
  // console.log(auth);
  // console.log(location);

  // custom code to log the user out if they are isActive false. we still need to hook up to cognito. this is temp solution.
  // this is for core FE - we have no is active field for Customer FE
  // if (
  //   auth.isAuthenticated &&
  //   loggedInUser?.id !== 'None' &&
  //   !loggedInUser?.isActive
  // ) {
  //   auth.logout();
  // }

  // If user not authenticated, show Login component
  if (!auth.isAuthenticated) {
    if (`${location.pathname}${location.search}` !== requestedLocation) {
      setRequestedLocation(`${location.pathname}${location.search}`);
    }
    return <Login tenantId={parsed?.org || ''} />;
  }

  console.log('Authenticated');
  // console.log(loggedInUser);

  // If user is authenticated but has no basic profile details, show simple sign up form
  if (loggedInUser?.id !== 'None' && loggedInUser?.fullName === '') {
    console.log('Initialising new customer.');

    return <NewUserProfile customerLocationId={decryptedData?.locationId} />;
  }

  // If user is authenticated and has basic profile, send user onwards to correct page
  if (
    requestedLocation &&
    `${location.pathname}${location.search}` !== requestedLocation
  ) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
