export const getTerminalPin = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = '1111';

  try {
    const terminalPins = {
      cityosteophysio: '1111',
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': '6666',
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': '1111',
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': '1111',
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': '1111',

      // Dev
      '46ef088a-d044-4000-8a53-578e6be74ef1': '1234'
    };

    const result = terminalPins[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};
