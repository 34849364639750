import callGraphQL, { subscribeGraphQL } from 'src/graphqlHelper';
import apiErrorHandler from 'src/utils/apiErrorHandler';
import {
  GetMyCustomerProfilesQuery,
  UpdateCustomerMinimalProfileMutation
} from 'src/API';

import { getMyCustomerProfiles } from 'src/graphql/queries';
import { updateCustomerMinimalProfile } from 'src/graphql/mutations';
import { customGetMyCustomerProfiles } from './queries';

export const getMyCustomersAPI = async () => {
  try {
    const customerProfiles = await callGraphQL<GetMyCustomerProfilesQuery>(
      customGetMyCustomerProfiles,
      {
        variables: {}
      }
    );
    return customerProfiles;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};

export const updateCustomerMinimalProfileAPI = async (
  fullName,
  mobileCountryCode,
  mobile,
  locationId
) => {
  try {
    const newProfile = await callGraphQL<UpdateCustomerMinimalProfileMutation>(
      updateCustomerMinimalProfile,
      {
        variables: { input: { fullName, mobileCountryCode, mobile, locationId } }
      }
    );
    return newProfile;
  } catch (error) {
    return { ...error, customErrorMessage: apiErrorHandler(error) };
  }
};
