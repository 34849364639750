export const getImageNoteOptions = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = [
    { label: 'Body Diagram', noteType: 'image_bodyAnnotation' }
  ];

  try {
    const imageNoteOptions = {
      cityosteophysio: [
        { label: 'Body Diagram', noteType: 'image_bodyAnnotation' }
      ],
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': [
        { label: 'Body Diagram', noteType: 'image_bodyAnnotation' }
      ],
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': [
        { label: 'Measurements', noteType: 'image_measurementAnnotation' },
        { label: 'Shirt Diagram', noteType: 'image_shirtAnnotation' },
        { label: 'Pants Diagram', noteType: 'image_pantsAnnotation' },
        {
          label: 'Suit-Jacket Diagram',
          noteType: 'image_suitJacketAnnotation'
        },
        {
          label: 'Suit-Trousers Diagram',
          noteType: 'image_suitTrousersAnnotation'
        },
        { label: 'Suit-Vest Diagram', noteType: 'image_suitVestAnnotation' }
      ],
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': [
        {
          label: 'History-Body Diagram',
          noteType: 'image_historyBodyAnnotation'
        }
      ],
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': []
    };

    const result = imageNoteOptions[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getStructuredNoteOptions = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = [];

  try {
    const structuredNoteOptions = {
      CityOsteoPhysio: [],
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': [],
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': [
        {
          label: 'Baseline Measurements',
          noteType: 'structured_Baseline_Measurements'
        },
        {
          label: 'Bio-Structural Examination',
          noteType: 'structured_Bio_Structural_Examination'
        },
        {
          label: 'Progress Examination',
          noteType: 'structured_Progress_Examination'
        },
        {
          label: 'X-ray Details',
          noteType: 'structured_Xray_Details'
        },
        {
          label: 'ROF Checkout',
          noteType: 'structured_Rof_Checkout'
        },
        {
          label: 'ROF Follow-up',
          noteType: 'structured_Rof_Followup'
        },
        {
          label: 'Team Activation',
          noteType: 'structured_Team_Activation'
        },
        {
          label: 'Home Care Protocol',
          noteType: 'structured_Home_Care_Protocol'
        },
        {
          label: 'R5 Checkout',
          noteType: 'structured_R5_Checkout'
        },
        {
          label: 'R5 Follow-up',
          noteType: 'structured_R5_Followup'
        },
        {
          label: 'Maintenance Plan Checkout',
          noteType: 'structured_Maintenance_Plan_Checkout'
        },
        {
          label: 'Plan Update',
          noteType: 'structured_Plan_Update'
        },
        {
          label: 'Patient Referral',
          noteType: 'structured_Patient_Referral'
        }
      ],
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': [],

      // Dev
      '9e3ae757-b4b1-464a-b6b9-9e7005e1f55': [
        { label: 'Sample Structured Note', noteType: 'structured_Sample_Note' }
      ],
      '46ef088a-d044-4000-8a53-578e6be74ef1': [
        { label: 'Sample Structured Note', noteType: 'structured_Sample_Note' }
      ]
    };

    const result = structuredNoteOptions[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getAllowEditAfterPublish = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = false;

  try {
    const allowEditAfterPublish = {
      cityosteophysio: false,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': true,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': true,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': false,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': true
    };

    const result = allowEditAfterPublish[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getAllowEditByOthers = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = false;

  try {
    const allowEditByOthers = {
      cityosteophysio: false,
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': true,
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': true,
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': true,
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': false
    };

    const result = allowEditByOthers[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getPinnedNoteTypes = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = [];

  try {
    const pinnedNoteOptions = {
      cityosteophysio: [],
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': [],
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': [],
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': ['image_historyBodyAnnotation'],
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': []
    };

    const result = pinnedNoteOptions[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getFlowProviderScreenBriefNoteKeys = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = [];

  try {
    const briefNoteKeys = {
      cityosteophysio: [],
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': [],
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': [],
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': [
        {
          type: 'image_historyBodyAnnotation',
          keys: [
            'main_complaints',
            'low_back_pain',
            'neck_shoulder_pain',
            'headache',
            'work_related_stress',
            'mental_stress',
            'mvas',
            'sports_exercises',
            'slips_and_falls',
            'immediate_goals',
            'short_term_goals',
            'long_term_goals'
          ]
        }
      ],
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': []
    };

    const result = briefNoteKeys[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getCompactNotesType = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = 'appointment';

  try {
    const compactNotesType = {
      cityosteophysio: 'appointment',
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': 'appointment',
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': 'appointment',
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': 'adjustment',
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': 'appointment'
    };

    const result = compactNotesType[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getImageDocsOfNoteType = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = '';

  try {
    const imageDocsOfNoteType = {
      cityosteophysio: '',
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': '',
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': '',
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b':
        'structured_Baseline_Measurements',
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': ''
    };

    const result = imageDocsOfNoteType[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};

export const getImageDocsFileName = (tenantId) => {
  const tenantIdLowerCase = tenantId?.toLowerCase();

  const fallback = '';

  try {
    const imageDocsFileName = {
      cityosteophysio: '',
      '1bf61688-fb26-4d17-bec7-81fc63a0c268': '',
      'cafc5729-45c7-4b4b-b9c9-3be479b46af5': '',
      '4e2bfe06-bd9f-434f-b524-9ec287abc92b': 'xray',
      '74af6e6e-aa4d-4f0a-a8e3-4e5a3c775d89': ''
    };

    const result = imageDocsFileName[tenantIdLowerCase];

    if (result) {
      return result;
    } else {
      return fallback;
    }
  } catch (err) {
    console.log(err);
    return fallback;
  }
};
