import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

import { getMyCustomersAPI } from 'src/content/Users/api';
// import {
//   listUsersAPI,
//   getLoggedInUserAPI,
//   updateUserAPI,
//   createPractitionerAPI,
//   listUsersOnLoadAPI
// } from 'src/content/Users/api';

interface UserState {
  isLoadingUsers: boolean;
  userList: any[];
  activeUserList: any[];
  inactiveUserList: any[];
  loggedInUser: { [key: string]: any };
  userErrorMessage: string;
  userSuccessMessage: string;
}

const initialState: UserState = {
  isLoadingUsers: false,
  userList: [],
  activeUserList: [],
  inactiveUserList: [],
  loggedInUser: {
    email: 'None',
    firstName: 'None',
    id: 'None',
    jobTitle: 'None',
    lastName: 'None',
    mobile: 'None',
    type: 'None',
    username: 'None',
    preferredName: 'None'
  },
  userErrorMessage: '',
  userSuccessMessage: ''
};

const sortUsersPreferredNames = (a, b) => {
  if (a.preferredName > b.preferredName) {
    return 1;
  }
  if (a.preferredName < b.preferredName) {
    return -1;
  }
  return 0;
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    listUsers(state: UserState, action: PayloadAction<any>) {
      const users = [...action.payload];

      // Sort user ASC by preferredName

      users.sort(sortUsersPreferredNames);
      state.userList = users;

      state.activeUserList = users.filter((user) => user.isActive);
      state.inactiveUserList = users.filter((user) => !user.isActive);

      state.isLoadingUsers = false;
    },

    getLoggedInUser(state: UserState, action: PayloadAction<any>) {
      state.loggedInUser = action.payload;
    },

    editUser(state: UserState, action: PayloadAction<any>) {
      const userIndex = state.userList.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (userIndex >= 0) {
        state.userList = state.userList.map((user, i) =>
          i === userIndex ? action.payload : user
        );
      }
    },

    createUser(state: UserState, action: PayloadAction<any>) {
      const newUser = action.payload;

      state.userList = [newUser, ...state.userList];

      if (newUser.isActive) {
        state.activeUserList = [newUser, ...state.activeUserList];
      }
    },

    setIsLoadingUsers(state: UserState, action: PayloadAction<any>) {
      state.isLoadingUsers = action.payload;
    },

    setErrorMessage(state: UserState, action: PayloadAction<any>) {
      state.userErrorMessage = action.payload;
    },

    setSuccessMessage(state: UserState, action: PayloadAction<any>) {
      state.userSuccessMessage = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const listUsers = (): AppThunk => async (dispatch) => {
  dispatch(startLoadingUsers());

  // const response = await backOff(() => listUsersAPI(), {
  //   numOfAttempts: 5,
  //   jitter: 'full'
  // });

  // if (!response.customErrorMessage) {
  //   dispatch(slice.actions.listUsers(response.data.listUsers));
  // } else {
  //   dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
  // }

  return dispatch(stopLoadingUsers());
};

export const getLoggedInUser =
  (emailOwnerId = ''): AppThunk =>
  async (dispatch) => {
    try {
      const response = await getMyCustomersAPI();
      // console.log(response);

      if (!response.customErrorMessage) {
        const mainUser =
          emailOwnerId !== ''
            ? response.data.getMyCustomerProfiles?.find(
                (usr) => usr.id === emailOwnerId
              )
            : response.data.getMyCustomerProfiles?.[0];

        // console.log(mainUser);

        dispatch(
          slice.actions.getLoggedInUser({
            ...mainUser,
            type: 'Admin',
            isActive: true
          })
        );

        dispatch(
          slice.actions.listUsers(
            response.data.getMyCustomerProfiles.map((user) => {
              return { ...user, type: 'Admin', isActive: true };
            })
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

export const editUser =
  (user): AppThunk =>
  async (dispatch) => {
    // const response = await updateUserAPI(user);
    // // console.log(response);
    // if (!response.customErrorMessage) {
    //   dispatch(slice.actions.editUser(response.data.updatePractitioner));
    //   dispatch(
    //     slice.actions.setSuccessMessage('User details successfully updated')
    //   );
    // } else {
    //   dispatch(slice.actions.setErrorMessage(response.customErrorMessage));
    // }
  };

export const startLoadingUsers = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingUsers(true));
};

export const stopLoadingUsers = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingUsers(false));
};

export const setErrorMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setErrorMessage(message));
  };

export const setSuccessMessage =
  (message): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSuccessMessage(message));
  };

export default slice;
