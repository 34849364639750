export const getImageNoteDetails = (
  imageNoteType: string
): { textData: any[]; imageUrl: string } => {
  try {
    const imageNoteDetails = {
      image_shirtAnnotation: {
        textData: [
          {
            name: 'fabric',
            label: 'Fabric',
            type: 'input',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'collar_type',
            label: 'Collar',
            type: 'select',
            required: false,
            notClearable: true,
            options: [
              { label: 'Small Spread', value: 'Small Spread' },
              { label: 'Spread', value: 'Spread' },
              { label: 'Continental', value: 'Continental' },
              { label: 'Cutaway', value: 'Cutaway' },
              { label: 'Special', value: 'Special' }
            ]
          },
          {
            name: 'cuff_type',
            label: 'Cuffs',
            type: 'select',
            required: false,
            notClearable: true,
            options: [
              { label: 'Single Barrel', value: 'Single Barrel' },
              { label: 'Double Barrel', value: 'Double Barrel' },
              { label: 'French', value: 'French' },
              { label: 'Special', value: 'Special' }
            ]
          },
          {
            name: 'additional_notes',
            label: 'Additional Notes',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          }
        ],
        imageUrl: '/static/measurement/measurement_shirt.png'
      },
      image_pantsAnnotation: {
        textData: [
          {
            name: 'fabric',
            label: 'Fabric',
            type: 'input',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'belt_area',
            label: 'Belt Area',
            type: 'select',
            required: false,
            notClearable: true,
            options: [
              { label: 'Belt-less Tab', value: 'Belt-less Tab' },
              { label: 'Belt-less Cinch', value: 'Belt-less Cinch' }
            ]
          },
          {
            name: 'coin_pocket',
            label: 'Coin Pocket',
            type: 'select',
            required: false,
            notClearable: true,
            options: [
              { label: '1', value: '1' },
              { label: '2', value: '2' }
            ]
          },
          {
            name: 'additional_notes',
            label: 'Additional Notes',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          }
        ],
        imageUrl: '/static/measurement/measurement_pants.png'
      },
      image_suitJacketAnnotation: {
        textData: [
          {
            name: 'fabric',
            label: 'Fabric',
            type: 'input',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'lining',
            label: 'Lining',
            type: 'input',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'additional_notes',
            label: 'Additional Notes',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          }
        ],
        imageUrl: '/static/measurement/measurement_suit_jacket.png'
      },
      image_suitTrousersAnnotation: {
        textData: [
          {
            name: 'fabric',
            label: 'Fabric',
            type: 'input',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'belt_area',
            label: 'Belt Area',
            type: 'select',
            required: false,
            notClearable: true,
            options: [
              { label: 'Belt-less Tab', value: 'Belt-less Tab' },
              { label: 'Belt-less Cinch', value: 'Belt-less Cinch' }
            ]
          },
          {
            name: 'coin_pocket',
            label: 'Coin Pocket',
            type: 'select',
            required: false,
            notClearable: true,
            options: [
              { label: '1', value: '1' },
              { label: '2', value: '2' }
            ]
          },
          {
            name: 'additional_notes',
            label: 'Additional Notes',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          }
        ],
        imageUrl: '/static/measurement/measurement_suit_trousers.png'
      },
      image_suitVestAnnotation: {
        textData: [
          {
            name: 'fabric',
            label: 'Fabric',
            type: 'input',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'styles',
            label: 'Styles',
            type: 'input',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'back_bodice',
            label: 'Back Bodice',
            type: 'select',
            required: false,
            notClearable: true,
            options: [
              { label: 'Self-fabric', value: 'Self-fabric' },
              { label: 'Lining', value: 'Lining' }
            ]
          },
          {
            name: 'additional_notes',
            label: 'Additional Notes',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          }
        ],
        imageUrl: '/static/measurement/measurement_suit_vest.png'
      },
      image_measurementAnnotation: {
        textData: [
          {
            name: 'additional_notes',
            label: 'Additional Notes',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          }
        ],
        imageUrl: '/static/measurement/measurement_form.png'
      },
      image_bodyAnnotation: {
        textData: [
          {
            name: 'additional_notes',
            label: 'Additional Notes',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          }
        ],
        imageUrl: '/static/body/body_physio.png'
      },
      image_historyBodyAnnotation: {
        textData: [
          {
            name: 'history_form',
            label: 'History Form',
            type: 'textDisplay',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'main_complaints',
            label: 'Main Complaint(s)',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'pain_points',
            label: 'Pain Points',
            type: 'textDisplay',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'low_back_pain',
            label: 'Low Back Pain',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'neck_shoulder_pain',
            label: 'Neck / Shoulder Pain',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'headache',
            label: 'Headache',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'pain_others',
            label: 'Others',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'life_limitations',
            label: 'Life Limitations',
            type: 'textDisplay',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'work',
            label: 'Work',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'family',
            label: 'Family',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'activities',
            label: 'Activities',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'limitations_others',
            label: 'Others',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'goal_getting',
            label: 'Goal Getting',
            type: 'textDisplay',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'immediate_goals',
            label: 'Immediate Goals (Pain)',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'short_term_goals',
            label: 'Short Term Goals (Do)',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'long_term_goals',
            label: 'Long Term Goals (Be)',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'stresses_impacts_injuries_traumas',
            label: 'Stresses, Impacts, Injuries and Traumas',
            type: 'textDisplay',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'work_related_stress',
            label: 'Work-related Stress',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'mental_stress',
            label:
              'Mental Stress (Deadlines, projects, working long hours, targets)',
            type: 'checkbox',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'mvas',
            label: 'MVAs',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'sports_exercises',
            label: 'Sports/Exercises',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'slips_and_falls',
            label: 'Slips and Falls',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'national_service',
            label: 'National Service (applicable?)',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'stress_others',
            label: 'Others',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'travel',
            label: 'Travel',
            type: 'textDisplay',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'travel_frequency',
            label:
              'Do you travel with your work? How much time per month on average are you out of Singapore?',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'elephants_present',
            label: 'Elephants Present',
            type: 'textDisplay',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'previous_chiropractic_experience',
            label:
              'Previous Chiropractic Experience (if applicable) - Who, Xrays taken, Subluxation, Frequency, Last Adjustment',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'slipped_disc',
            label: '"Slipped Disc"',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'physiotherapy',
            label: 'Physiotherapy',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'md_ortho',
            label: 'MD/Ortho',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'muscle_strain',
            label: 'Muscle Strain',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'degeneration',
            label: 'Degeneration',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'scollosis',
            label: 'Scollosis',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'elephants_others',
            label: 'Others',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'xray_commitment',
            label: 'X-Ray commitment',
            type: 'textDisplay',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'date_of_xrays',
            label: 'Date of X-Rays',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'additional',
            label: 'Additional',
            type: 'textDisplay',
            required: false,
            notClearable: false,
            options: []
          },
          {
            name: 'additional_notes',
            label: 'Additional Notes',
            type: 'multilineInput',
            required: false,
            notClearable: false,
            options: []
          }
        ],
        imageUrl: '/static/body/body_chiro.png'
      }
    };

    const selectedImageNote = imageNoteDetails[imageNoteType];

    return selectedImageNote;
  } catch {
    return { textData: [], imageUrl: 'Unknown' };
  }
};

export const getStructuredNoteDetails = (structuredNoteType: string): {} => {
  try {
    const structuredNoteDetails = {
      structured_Baseline_Measurements: {
        header: 'Baseline Measurements',
        sections: [
          {
            sectionHeader: 'Range of Motion',
            sectionQuestions: [
              {
                name: 'cervical_extension',
                label: 'Cervical extension',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'cervical_lateral_flexion_left',
                label: 'Cervical lateral flexion (Left)',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'cervical_lateral_flexion_right',
                label: 'Cervical lateral flexion (Right)',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'lumbar_flexion',
                label: 'Lumbar flexion',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'lumbar_extension',
                label: 'Lumbar extension',
                type: 'input',
                required: true,
                options: []
              }
            ]
          },
          {
            sectionHeader: 'Bilateral Weight Distribution',
            sectionQuestions: [
              {
                name: 'blw_left_lbs',
                label: 'Left (lbs)',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'blw_right_lbs',
                label: 'Right (lbs)',
                type: 'input',
                required: true,
                options: []
              }
            ]
          },
          {
            sectionHeader: 'Grip Strength',
            sectionQuestions: [
              {
                name: 'gs_left_lbs',
                label: 'Left (lbs)',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'gs_right_lbs',
                label: 'Right (lbs)',
                type: 'input',
                required: true,
                options: []
              }
            ]
          },
          {
            sectionHeader: 'Photos taken',
            sectionQuestions: [
              {
                name: 'posture_photo',
                label: 'Posture photo',
                type: 'checkbox',
                required: false,
                options: []
              },
              {
                name: 'profile_photo',
                label: 'Profile photo',
                type: 'checkbox',
                required: false,
                options: []
              }
            ]
          }
        ]
      },
      structured_Bio_Structural_Examination: {
        header: 'Bio-Structural Examination',
        sections: [
          {
            sectionHeader: '',
            sectionQuestions: [
              {
                name: 'bse_session',
                label: 'BSE Session #',
                type: 'input',
                required: true,
                options: []
              }
            ]
          },
          {
            sectionHeader: 'Range of Motion',
            sectionQuestions: [
              {
                name: 'cervical_extension',
                label: 'Cervical extension',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'cervical_lateral_flexion_left',
                label: 'Cervical lateral flexion (Left)',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'cervical_lateral_flexion_right',
                label: 'Cervical lateral flexion (Right)',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'lumbar_flexion',
                label: 'Lumbar flexion',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'lumbar_extension',
                label: 'Lumbar extension',
                type: 'input',
                required: true,
                options: []
              }
            ]
          },
          {
            sectionHeader: '',
            sectionQuestions: [
              {
                name: 'conversation_pointers',
                label:
                  'This is a placeholder for Amanda to provide some short pointers so that you can have a better conversation with the patients',
                type: 'textDisplay',
                required: false,
                options: []
              },
              {
                name: 'conversation',
                label: 'Conversation',
                type: 'multilineInput',
                required: false,
                options: []
              }
            ]
          },
          {
            sectionHeader: '',
            sectionQuestions: [
              {
                name: 'mq',
                label: 'MQ?',
                type: 'select',
                required: false,
                options: [
                  { label: 'MQ+', value: 'MQ+' },
                  { label: 'MQ-', value: 'MQ-' },
                  { label: 'Unclear', value: 'Unclear' }
                ]
              }
            ]
          }
        ]
      },
      structured_Progress_Examination: {
        header: 'Progress Examination',
        sections: [
          {
            sectionHeader: '',
            sectionQuestions: [
              {
                name: 'progress_examination',
                label:
                  'Progress Examination (90 Day / Mid-Term / Month 6 / Month 8 / Month 12 / Month XX)',
                type: 'input',
                required: true,
                options: []
              }
            ]
          },
          {
            sectionHeader: 'Range of Motion',
            sectionQuestions: [
              {
                name: 'cervical_extension',
                label: 'Cervical extension',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'cervical_lateral_flexion_left',
                label: 'Cervical lateral flexion (Left)',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'cervical_lateral_flexion_right',
                label: 'Cervical lateral flexion (Right)',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'lumbar_flexion',
                label: 'Lumbar flexion',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'lumbar_extension',
                label: 'Lumbar extension',
                type: 'input',
                required: true,
                options: []
              }
            ]
          },
          {
            sectionHeader: 'Bilateral Weight Distribution',
            sectionQuestions: [
              {
                name: 'blw_left_lbs',
                label: 'Left (lbs)',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'blw_right_lbs',
                label: 'Right (lbs)',
                type: 'input',
                required: true,
                options: []
              }
            ]
          },
          {
            sectionHeader: 'Grip Strength',
            sectionQuestions: [
              {
                name: 'gs_left_lbs',
                label: 'Left (lbs)',
                type: 'input',
                required: true,
                options: []
              },
              {
                name: 'gs_right_lbs',
                label: 'Right (lbs)',
                type: 'input',
                required: true,
                options: []
              }
            ]
          },
          {
            sectionHeader: 'Photos taken',
            sectionQuestions: [
              {
                name: 'posture_photo',
                label: 'Posture photo',
                type: 'checkbox',
                required: false,
                options: []
              }
            ]
          },
          {
            sectionHeader: '',
            sectionQuestions: [
              {
                name: 'conversation_pointers',
                label:
                  'This is a placeholder for Amanda to provide some short pointers so that you can have a better conversation with the patients',
                type: 'textDisplay',
                required: false,
                options: []
              },
              {
                name: 'conversation',
                label: 'Conversation',
                type: 'multilineInput',
                required: false,
                options: []
              }
            ]
          }
        ]
      },
      structured_Xray_Details: {
        header: 'X-ray Details',
        sections: [
          {
            sectionHeader: 'X-Ray Details',
            sectionQuestions: [
              {
                name: 'date_of_xray',
                label: 'X-Ray Date',
                type: 'datePicker',
                required: true,
                options: []
              }
            ]
          },
          {
            sectionHeader: 'Additional Details',
            sectionQuestions: [
              {
                name: 'comments',
                label: 'Comments',
                type: 'multilineInput',
                required: false,
                options: []
              }
            ]
          }
        ]
      },
      structured_Rof_Checkout: {
        header: 'ROF Checkout',
        sections: [
          {
            sectionHeader: 'ROF Details',
            sectionQuestions: [
              {
                name: 'date_of_rof',
                label: 'Date of ROF',
                type: 'datePicker',
                required: true,
                options: []
              },
              {
                name: 'started_Care',
                label: 'Started Care',
                type: 'select',
                required: true,
                options: [
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' }
                ]
              },
              {
                name: 'wffo',
                label: 'WFFO',
                type: 'select',
                required: true,
                options: [
                  { label: 'NA', value: 'NA' },
                  { label: '3 Adults', value: '3 Adults' },
                  { label: '2 Adults & 1 Child', value: '2 Adults & 1 Child' },
                  { label: 'Variable Plan', value: 'Variable Plan' }
                ]
              }
            ]
          },
          {
            sectionHeader: 'Additional Details',
            sectionQuestions: [
              {
                name: 'comments',
                label: 'Comments',
                type: 'multilineInput',
                required: false,
                options: []
              }
            ]
          }
        ]
      },
      structured_Rof_Followup: {
        header: 'ROF Follow-up',
        sections: [
          {
            sectionHeader: 'ROF Details',
            sectionQuestions: [
              {
                name: 'date_of_rof',
                label: 'Date of ROF Follow-up',
                type: 'datePicker',
                required: true,
                options: []
              },
              {
                name: 'which_re23',
                label: 'Which ReR23 is this',
                type: 'select',
                required: true,
                options: [
                  { label: 'ReR23', value: 'ReR23' },
                  { label: '2ReR23', value: '2ReR23' },
                  { label: '3ReR23', value: '3ReR23' }
                ]
              },
              {
                name: 'started_Care',
                label: 'Started Care',
                type: 'select',
                required: true,
                options: [
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' }
                ]
              },
              {
                name: 'wffo',
                label: 'WFFO',
                type: 'select',
                required: true,
                options: [
                  { label: 'NA', value: 'NA' },
                  { label: '3 Adults', value: '3 Adults' },
                  { label: '2 Adults & 1 Child', value: '2 Adults & 1 Child' },
                  { label: 'Variable Plan', value: 'Variable Plan' }
                ]
              }
            ]
          },
          {
            sectionHeader: 'Additional Details',
            sectionQuestions: [
              {
                name: 'comments',
                label: 'Comments',
                type: 'multilineInput',
                required: false,
                options: []
              }
            ]
          }
        ]
      },
      structured_Team_Activation: {
        header: 'Team Activation (Review Received)',
        sections: [
          {
            sectionHeader: 'Team Activation Details',
            sectionQuestions: [
              {
                name: 'date_of_review_received',
                label: 'Date of Review Received',
                type: 'datePicker',
                required: true,
                options: []
              },
              {
                name: 'location',
                label: 'Location',
                type: 'select',
                required: true,
                options: 'locationOptions'
              },
              // {
              //   name: 'transaction',
              //   label: 'Transaction',
              //   type: 'select',
              //   required: true,
              //   options: [
              //     {
              //       label: 'Testimonial Given',
              //       value: 'Testimonial Given'
              //     },
              //     {
              //       label: 'Testimonial Received',
              //       value: 'Testimonial Received'
              //     },
              //     {
              //       label: 'Testimonial Photo',
              //       value: 'Testimonial Photo'
              //     },
              //     {
              //       label: 'Referral Pack',
              //       value: 'Referral Pack'
              //     }
              //   ]
              // },
              {
                name: 'given_by',
                label: 'Given by',
                type: 'select',
                required: true,
                options: 'userOptions'
              }
            ]
          },
          {
            sectionHeader: 'Additional Details',
            sectionQuestions: [
              {
                name: 'comments',
                label: 'Comments',
                type: 'multilineInput',
                required: false,
                options: []
              }
            ]
          }
        ]
      },
      structured_Home_Care_Protocol: {
        header: 'Home Care Protocol',
        sections: [
          {
            sectionHeader: 'Home Care Details',
            sectionQuestions: [
              {
                name: 'date_of_home_care',
                label: 'Date of Home Care',
                type: 'datePicker',
                required: true,
                options: []
              },
              {
                name: 'location',
                label: 'Location',
                type: 'select',
                required: true,
                options: 'locationOptions'
              }
            ]
          },
          {
            sectionHeader: 'Additional Details',
            sectionQuestions: [
              {
                name: 'comments',
                label: 'Comments',
                type: 'multilineInput',
                required: false,
                options: []
              }
            ]
          }
        ]
      },
      structured_R5_Checkout: {
        header: 'R5 Checkout',
        sections: [
          {
            sectionHeader: 'R5 Checkout Details',
            sectionQuestions: [
              {
                name: 'date_of_r5',
                label: 'Date of R5',
                type: 'datePicker',
                required: true,
                options: []
              },
              {
                name: 'resigned',
                label: 'Re-signed',
                type: 'select',
                required: true,
                options: [
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' }
                ]
              }
            ]
          },
          {
            sectionHeader: 'Additional Details',
            sectionQuestions: [
              {
                name: 'comments',
                label: 'Comments',
                type: 'multilineInput',
                required: false,
                options: []
              }
            ]
          }
        ]
      },
      structured_R5_Followup: {
        header: 'R5 Follow-up',
        sections: [
          {
            sectionHeader: 'R5 Follow-Up Details',
            sectionQuestions: [
              {
                name: 'date_of_r5_followup',
                label: 'Date of R5 Follow-up',
                type: 'datePicker',
                required: true,
                options: []
              },
              {
                name: 'which_r5',
                label: 'Which R5 is this',
                type: 'select',
                required: true,
                options: [
                  {
                    label: '4M R5',
                    value: '4M R5'
                  },
                  { label: '90D R5', value: '90D R5' },
                  { label: 'MT R5', value: 'MT R5' },
                  { label: 'M8 R5', value: 'M8 R5' },
                  { label: '12M R5', value: '12M R5' },
                  { label: 'WC R5', value: 'WC R5' }
                ]
              },
              {
                name: 'which_rer5',
                label: 'Which ReR5 is this',
                type: 'select',
                required: true,
                options: [
                  { label: 'ReR5', value: 'ReR5' },
                  { label: '2ReR5', value: '2ReR5' },
                  { label: '3ReR5', value: '3ReR5' }
                ]
              },
              {
                name: 'resigned',
                label: 'Re-signed',
                type: 'select',
                required: true,
                options: [
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' }
                ]
              }
            ]
          },
          {
            sectionHeader: 'Additional Details',
            sectionQuestions: [
              {
                name: 'comments',
                label: 'Comments',
                type: 'multilineInput',
                required: false,
                options: []
              }
            ]
          }
        ]
      },
      structured_Maintenance_Plan_Checkout: {
        header: 'Maintenance Plan Checkout',
        sections: [
          {
            sectionHeader: 'Maintenance Plan Checkout Details',
            sectionQuestions: [
              {
                name: 'date_of_mp',
                label: 'Date of MP CR',
                type: 'datePicker',
                required: true,
                options: []
              },
              {
                name: 'resigned',
                label: 'Re-signed',
                type: 'select',
                required: true,
                options: [
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' }
                ]
              }
            ]
          },
          {
            sectionHeader: 'Additional Details',
            sectionQuestions: [
              {
                name: 'comments',
                label: 'Comments',
                type: 'multilineInput',
                required: false,
                options: []
              }
            ]
          }
        ]
      },
      structured_Plan_Update: {
        header: 'Plan Update',
        sections: [
          {
            sectionHeader: 'Plan Update Details',
            sectionQuestions: [
              {
                name: 'date_of_update',
                label: 'Date of Update',
                type: 'datePicker',
                required: true,
                options: []
              },
              {
                name: 'practitioner',
                label: 'Practitioner',
                type: 'select',
                required: true,
                options: 'providerOptions'
              },
              {
                name: 'location',
                label: 'Location',
                type: 'select',
                required: true,
                options: 'locationOptions'
              },
              {
                name: 'transaction',
                label: 'Transaction',
                type: 'select',
                required: true,
                options: [
                  {
                    label: 'BD Stop Care',
                    value: 'BD Stop Care'
                  },
                  {
                    label: 'SD Stop Care',
                    value: 'SD Stop Care'
                  },
                  {
                    label: 'Suspend Care',
                    value: 'Suspend Care'
                  },
                  {
                    label: 'Resume Care',
                    value: 'Resume Care'
                  }
                ]
              }
            ]
          },
          {
            sectionHeader: 'Additional Details',
            sectionQuestions: [
              {
                name: 'comments',
                label: 'Comments',
                type: 'multilineInput',
                required: false,
                options: []
              }
            ]
          }
        ]
      },
      structured_Patient_Referral: {
        header: 'Patient Referral',
        sections: [
          {
            sectionHeader: 'Patient Referral Details',
            sectionQuestions: [
              {
                name: 'referred_by',
                label: 'Referred by (insert patient code)',
                type: 'input',
                required: true,
                options: []
              }
            ]
          },
          {
            sectionHeader: 'Additional Details',
            sectionQuestions: [
              {
                name: 'comments',
                label: 'Comments',
                type: 'multilineInput',
                required: false,
                options: []
              }
            ]
          }
        ]
      },
      structured_Acupuncture_Points: {
        header: 'Acupunture Points',
        sections: [
          {
            sectionHeader: 'Head',
            sectionQuestions: [
              {
                name: 'a1',
                label: '风府 (双)',
                type: 'checkbox',
                required: false,
                options: []
              },
              {
                name: 'a2',
                label: '百劳 (双)',
                type: 'checkbox',
                required: false,
                options: []
              }
            ]
          },
          {
            sectionHeader: 'Body',
            sectionQuestions: [
              {
                name: 'b1',
                label: '肩井 (双)',
                type: 'checkbox',
                required: false,
                options: []
              },
              {
                name: 'b2',
                label: '大予 (双)',
                type: 'checkbox',
                required: false,
                options: []
              }
            ]
          },
          {
            sectionHeader: '',
            sectionQuestions: [
              {
                name: 'conversation',
                label: '其他问题和资料',
                type: 'multilineInput',
                required: false,
                options: []
              }
            ]
          }
        ]
      },
      structured_Sample_Note: {
        header: 'Sample',
        sections: [
          {
            sectionHeader: 'Referral Info',
            sectionQuestions: [
              {
                name: 'referral_name',
                label: 'Referral Name',
                type: 'input',
                required: true,
                options: []
              }
            ]
          },
          {
            sectionHeader: 'Some dates to collect',
            sectionQuestions: [
              {
                name: 'date_of_observation',
                label: 'Observation Date',
                type: 'datePicker',
                required: true,
                options: []
              },
              {
                name: 'date_of_followup',
                label: 'Follow-Up Date',
                type: 'datePicker',
                required: true,
                options: []
              }
            ]
          },
          {
            sectionHeader: 'Declaration',
            sectionQuestions: [
              {
                name: 'declaration',
                label:
                  'Confirmed that the above information has been verified by the patient.',
                type: 'checkbox',
                required: false,
                options: []
              }
            ]
          },
          {
            sectionHeader: 'Some header again..',
            sectionQuestions: [
              {
                name: 'display_prompt',
                label: 'This is a placeholder for some text',
                type: 'textDisplay',
                required: false,
                options: []
              },
              {
                name: 'conversation',
                label: 'Conversation',
                type: 'multilineInput',
                required: false,
                options: []
              }
            ]
          }
        ]
      } // end of example
    };

    const selectedStructuredNote = structuredNoteDetails[structuredNoteType];

    return selectedStructuredNote;
  } catch {
    return {};
  }
};
